/*********************************[POLICES]************************************/
@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("/ressources/fonts/montserrat/Montserrat.woff2") format("woff2"), url("/ressources/fonts/montserrat/Montserrat.woff") format("woff");
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  font-display: swap;
  src: url("/ressources/fonts/fontawesome/fa-solid-900.woff2") format("woff2"), url("/ressources/fonts/fontawesome/fa-solid-900.woff") format("woff");
}
.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
/*******************************[COLORS UTILISES]******************************/
/********************************[THEMES]**************************************/
/**BLEU**/
/**ROUGE**/
/**BEIGE**/
/**VERT**/
/**VIOLET**/
/**ROSE**/
/**GRIS**/
/**NOIR**/
/**ORANGE**/
/**BLEU GRIS**/
/**JAUNE**/
/**MARRON**/
/**FUSHIA**/
/**MAUVE**/
/*******************************[AUTRES THEMES]********************************/
/**AUTRE BEIGE**/
/**AUTRE VERT**/
/**AUTRE VIOLET**/
/**AUTRE ORANGE**/
/*************************************[BASES]**********************************/
/**All**/
* {
  margin: 0;
  padding: 0;
}
/**HTML & BODY**/
html,
body {
  height: 100%;
}
body {
  font-size: 100%;
  background: #16374A url(/ressources/images/backgrond_body_base.png) no-repeat center top !important;
}
.ui.header {
  font-weight: 500;
}
.ui.button,
.ui.buttons .button,
.ui.buttons .or {
  font-family: "Montserrat", sans-serif;
  font-weight: 500!important;
}
/**TITRES**/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600!important;
  color: #3192C5;
  margin-top: 0px;
  letter-spacing: -1px;
  font-family: "Montserrat", sans-serif;
}
h1 {
  font-size: 33px;
}
h1.blog_admin {
  text-transform: uppercase;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 23px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 13px;
}
h6 {
  font-size: 8px;
}
/**DIVIDER**/
#divider {
  border-bottom: 1px solid #B1D6ED;
  width: 100%;
}
#divider.forum {
  border-bottom: 1px dotted rgba(180, 186, 194, 0.57);
  padding: 0;
  margin-left: 60px;
  margin-right: 0px;
}
#divider.articles_recents_blog {
  border-bottom: 1px solid rgba(204, 204, 204, 0.31);
}
/**LIEN**/
a {
  color: #3192C5;
  word-break: break-word;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
  color: #23527c;
}
a:hover > i {
  text-decoration: none!important;
}
i {
  text-decoration: none!important;
}
i#msgbox_icon.blue {
  color: #3192C5;
}
i#msgbox_icon.red {
  color: red;
}
p {
  font-weight: 400;
}
p,
.item,
h1,
h2,
h3,
h4,
h5,
.description,
font {
  word-break: break-word;
  font-family: "Montserrat", sans-serif;
}
b,
strong {
  font-weight: 600!important;
}
.center_text {
  text-align: center;
}
.left_text {
  text-align: left;
}
.right_text {
  text-align: right;
}
.no_underline {
  text-decoration: none!important;
}
.is-hidden {
  display: none;
}
/**MARGIN**/
.mrg_auto {
  margin: auto!important;
}
.mrg_0 {
  margin: 0!important;
}
.mrg_5 {
  margin: 5px!important;
}
.mrg_10 {
  margin: 10px!important;
}
.mrg_15 {
  margin: 15px!important;
}
.mrg_20 {
  margin: 20px!important;
}
.mrg_1rem {
  margin: 1rem!important;
}
/**PADDING**/
.pdd_0 {
  padding: 0 !important;
}
.pdd_5 {
  padding: 5px !important;
}
.pdd_10 {
  padding: 10px !important;
}
.pdd_15 {
  padding: 15px !important;
}
.pdd_20 {
  padding: 20px !important;
}
.pdd_1rem {
  padding: 1rem !important;
}
/* INCREMENT PADDING/MARGIN */
.pdd_top_0 {
  padding-top: 0px !important;
}
.pdd_top_5 {
  padding-top: 5px !important;
}
.pdd_top_10 {
  padding-top: 10px !important;
}
.pdd_top_15 {
  padding-top: 15px !important;
}
.pdd_top_20 {
  padding-top: 20px !important;
}
.pdd_top_1rem {
  padding-top: 1rem !important;
}
.pdd_top_2rem {
  padding-top: 2rem !important;
}
.pdd_bottom_0 {
  padding-bottom: 0px !important;
}
.pdd_bottom_5 {
  padding-bottom: 5px !important;
}
.pdd_bottom_10 {
  padding-bottom: 10px !important;
}
.pdd_bottom_15 {
  padding-bottom: 15px !important;
}
.pdd_bottom_20 {
  padding-bottom: 20px !important;
}
.pdd_bottom_1rem {
  padding-bottom: 1rem !important;
}
.pdd_bottom_2rem {
  padding-bottom: 2rem !important;
}
.pdd_left_0 {
  padding-left: 0px !important;
}
.pdd_left_5 {
  padding-left: 5px !important;
}
.pdd_left_10 {
  padding-left: 10px !important;
}
.pdd_left_15 {
  padding-left: 15px !important;
}
.pdd_left_20 {
  padding-left: 20px !important;
}
.pdd_left_1rem {
  padding-left: 1rem !important;
}
.pdd_right_0 {
  padding-right: 0px !important;
}
.pdd_right_5 {
  padding-right: 5px !important;
}
.pdd_right_10 {
  padding-right: 10px !important;
}
.pdd_right_15 {
  padding-right: 15px !important;
}
.pdd_right_20 {
  padding-right: 20px !important;
}
.pdd_right_1rem {
  padding-right: 1rem !important;
}
.mrg_top_0 {
  margin-top: 0px !important;
}
.mrg_top_5 {
  margin-top: 5px !important;
}
.mrg_top_10 {
  margin-top: 10px !important;
}
.mrg_top_15 {
  margin-top: 15px !important;
}
.mrg_top_20 {
  margin-top: 20px !important;
}
.mrg_top_1rem {
  margin-top: 1rem !important;
}
.mrg_top_2rem {
  margin-top: 2rem !important;
}
.mrg_top_3rem {
  margin-top: 3rem !important;
}
.mrg_bottom_0 {
  margin-bottom: 0px !important;
}
.mrg_bottom_5 {
  margin-bottom: 5px !important;
}
.mrg_bottom_10 {
  margin-bottom: 10px !important;
}
.mrg_bottom_15 {
  margin-bottom: 15px !important;
}
.mrg_bottom_20 {
  margin-bottom: 20px !important;
}
.mrg_bottom_1rem {
  margin-bottom: 1rem !important;
}
.mrg_bottom_2rem {
  margin-bottom: 2rem !important;
}
.mrg_left_0 {
  margin-left: 0px !important;
}
.mrg_left_5 {
  margin-left: 5px !important;
}
.mrg_left_10 {
  margin-left: 10px !important;
}
.mrg_left_15 {
  margin-left: 15px !important;
}
.mrg_left_20 {
  margin-left: 20px !important;
}
.mrg_left_1rem {
  margin-left: 1rem !important;
}
.mrg_left_2rem {
  margin-left: 2rem !important;
}
.mrg_right_0 {
  margin-right: 0px !important;
}
.mrg_right_5 {
  margin-right: 5px !important;
}
.mrg_right_10 {
  margin-right: 10px !important;
}
.mrg_right_15 {
  margin-right: 15px !important;
}
.mrg_right_20 {
  margin-right: 20px !important;
}
.mrg_right_1rem {
  margin-right: 1rem !important;
}
.mrg_right_2rem {
  margin-right: 2rem !important;
}
/**DISPLAY**/
.display_block {
  display: block!important;
}
.display_none {
  display: none;
}
.display_inline {
  display: inline;
}
.display_initial {
  display: initial!important;
}
/**FLOAT**/
.float_left {
  float: left;
}
.float_right {
  float: right;
}
/**WIDTH & HEIGHT**/
.width_100 {
  width: 100%;
}
.height_100 {
  height: 100%;
}
/**BORDER**/
.no_border {
  border: none!important;
}
.border_radius_0 {
  border-radius: 0!important;
}
/**FONT_WEIGHT**/
.font_weight_bold {
  font-weight: bold;
}
/**SEMANTIC UI**/
.field .ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  text-transform: capitalize;
}
.ui.dividing.header {
  letter-spacing: 0px;
  margin-bottom: 20px;
}
.ui.top.attached.header {
  border-radius: 0px;
}
.ui.form .field > label {
  font-size: 1.0em;
  margin: 0em 0em 0.7rem 0em;
}
.ui.vertical.left.pointing.menu .item:after {
  right: auto;
  left: 0;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  margin: 0 0 0 -0.5px;
  border: none;
  border-bottom: 1px solid #D4D4D5;
  border-left: 1px solid #D4D4D5;
  z-index: 0;
}
.ui.vertical.menu .item > i.icon {
  width: 1.18em;
  float: left;
  margin: 0em 0.5em 0em 0em;
}
.ui.dropdown .menu {
  will-change: unset;
}
.justify.aligned {
  text-align: justify;
}
/**AUTRE**/
.theme-checkbox-default {
  display: none !important;
}
.cropper-bg {
  margin: auto;
}
@viewport {
  width: device-width;
  zoom: 1;
}
/**TARTE AU CITRON : BANDEAU COOKIES**/
#tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  display: none;
}
#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitron #tarteaucitronPrivacyUrl {
  padding: 4px 10px!important;
}
#tarteaucitronPersonalize {
  background: #3192C5 !important;
}
/*******************************[CONTAINER GENERAL]****************************/
#container_general {
  background: #FFF;
  min-height: 100%;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
@media only screen and (max-width: 768px) {
  .ui.container {
    width: auto!important;
    margin-left: 1em!important;
    margin-right: 1em!important;
  }
}
/*****************[CONTAINER PARTIE GAUCHE + DROITE]***************************/
#container_contenu_global {
  padding: 30px 30px 100px 30px;
}
#container_contenu_global.message_contenu {
  padding-left: 0px;
  padding-right: 0px;
}
#container_contenu_global ul,
#container_contenu_global ol {
  margin-bottom: 15px;
  padding-left: 20px;
  font-weight: 400!important;
}
#container_contenu_global table.is-table {
  margin-bottom: 15px;
  border-collapse: collapse;
}
#container_contenu_global table.is-table th {
  background: #3192C5;
  color: #fff;
}
#container_contenu_global table.is-table td,
#container_contenu_global table.is-table th {
  padding: 15px;
  border: 1px solid #000;
}
#container_contenu_global.cgu_mentionslegales p {
  text-align: justify;
}
#container_contenu_global.contenu_pc p {
  text-align: justify;
}
#container_contenu_global.contenu_pc .indentation_1rem {
  text-indent: 1rem;
}
#container_contenu_global.contenu_pc .indentation_2rem {
  text-indent: 2rem;
}
#container_contenu_global.contenu_pc .indentation_3rem {
  text-indent: 3rem;
}
#grid_contenu_global {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0;
  width: 100%;
}
/**************************[CONTAINER PARTIE GAUCHE]***************************/
#container_left.administration {
  padding-top: 18px;
}
@media all and (max-width: 1200px) {
  #grid_contenu_global {
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }
}
@media all and (max-width: 1080px) {
  #container_left {
    width: 100%!important;
  }
}
@media all and (max-width: 400px) {
  #divider.forum {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  #grid_contenu_global {
    width: 100%;
  }
}
/****************************[CONTAINER PARTIE DROITE]*************************/
#container_droite {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (max-width: 1080px) {
  #container_droite {
    width: 100%!important;
    padding-left: 0;
    padding-right: 0;
  }
  #container_droite.container_blogs {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #container_droite.contact {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #container_contenu_global.message_contenu {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #container_contenu_global.articles {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
/********************************[MODAL ENREGISTRER OK]************************/
@media all and (max-width: 767px) {
  .ui.modal .image.content {
    text-align: center;
  }
  #container_contenu_global.container_forum_home {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
}
@media all and (max-width: 658px) {
  .ui.modal .image.content {
    text-align: center;
  }
  #container_contenu_global.forum_sujet {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
}
@media all and (max-width: 400px) {
  #container_contenu_global {
    padding-left: 0;
    padding-right: 0;
  }
}
/********************************[TARTE AU CITRON]*****************************/
@media all and (max-width: 670px) {
  #tarteaucitronRoot #tarteaucitronAlertBig {
    display: inline-grid;
  }
  #tarteaucitronAlertBig #tarteaucitronCloseAlert,
  #tarteaucitronAlertBig #tarteaucitronPersonalize,
  #tarteaucitron #tarteaucitronPrivacyUrl {
    margin-left: 0px!important;
    margin-top: 5px!important;
    max-width: 88%!important;
  }
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
    padding-right: 30px;
  }
}
/********************************[RECAPTCHA]***********************************/
@media all and (max-width: 375px) {
  .g-recaptcha > div {
    width: 100%!important;
  }
  .g-recaptcha iframe {
    width: 100%;
    height: 100%;
  }
}
/*******************************[IE]********************************/
.ie11 #menu_principal > a {
  word-break: keep-all;
}
.ie11 #menu_principal .mega-dropdown .megamenu > .column > .column > .list > ul > li > a > span {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 1rem;
  word-break: keep-all;
}
.ie11 #menu_principal .mega-dropdown .megamenu > .column > .column > .list .container_img_menu.multi_img a {
  margin-bottom: 1rem!important;
  display: block;
}
.ie11 #container_search_rs #container_rs > .grid {
  padding-top: 1rem;
}
.ie11 .formulaire_repondre_sujet {
  display: block;
}
.ie11 #container_droite.sujet_forum > .grid > #bloc_pubs > .grid {
  display: block;
}
.ie11 #container_contenu_global #container_repondre > #bloc_repondre {
  display: block;
}
.ie11 #container_left #item_liste_articles > .item {
  display: block;
}
.ie11 #container_left .menu_lateral_admin {
  display: block;
}
.ie11 .description,
.ie11 .item,
.ie11 h1,
.ie11 h2,
.ie11 h3,
.ie11 h4,
.ie11 h5,
.ie11 h6,
.ie11 p {
  word-break: keep-all;
}
.ie11 #bloc_contenu_right > .grid {
  display: block;
}
.ie11 #bloc_contenu_right > .grid #bloc_publie_par {
  display: block;
}
.ie11 #container_footer .grid > #bloc_liens_footer {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 0;
}
/********************************[FOOTER]********************************/
#container_footer {
  bottom: 0;
  height: 0px;
  position: absolute;
}
#container_footer #footer_base {
  min-height: 150px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
#container_footer #footer_base .grid > #bloc_logo {
  padding: 3rem;
}
#container_footer .footer#footer_blog {
  background: #2D4962;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
#container_footer .footer#footer_blog > .grid > #bloc_liens_footer > .grid #bloc_mentions .content a {
  color: #FFF!important;
  font-size: 14px;
}
#container_footer .footer#footer_blog > .grid > #bloc_liens_footer > .grid #bloc_mentions a:hover {
  text-decoration: underline;
}
#container_footer .footer#footer_blog > .grid > #bloc_liens_footer > .grid #bloc_mentions.ui.horizontal.divided.list > .item:first-child {
  border-left: none!important;
}
#container_footer .footer#footer_blog > .grid > #bloc_liens_footer > .grid #bloc_mentions.ui.divided.horizontal.list > .item {
  border-left: 1px solid #FFF!important;
}
#container_footer .grid > #bloc_logo {
  padding: 2rem 3rem;
}
#container_footer .grid > #bloc_liens_footer {
  margin: auto;
}
#container_footer .grid > #bloc_liens_footer > .grid {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.52);
  padding-bottom: 2px;
}
#container_footer .grid > #bloc_liens_footer > .grid #bloc_mentions {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 0.2rem;
}
#container_footer .grid > #bloc_liens_footer > .grid #bloc_mentions .ui.horizontal.list {
  font-size: inherit;
}
#container_footer .grid > #bloc_liens_footer > .grid #bloc_mentions.rs {
  margin-top: 0.1rem;
}
#container_footer .grid > #bloc_liens_footer > .grid #bloc_mentions.copyright {
  margin-top: 2px;
}
#container_footer .grid > #bloc_liens_footer > .grid #bloc_mentions > a {
  color: #cecece;
}
#container_footer .grid > #bloc_liens_footer > .grid #bloc_mentions a i.fab {
  width: 12.5px;
  height: 14px;
  filter: invert(49%) sepia(44%) saturate(669%) hue-rotate(156deg) brightness(94%) contrast(98%);
}
#container_footer .grid > #bloc_liens_footer > .grid #bloc_mentions a:hover i.fab {
  filter: invert(27%) sepia(52%) saturate(615%) hue-rotate(166deg) brightness(97%) contrast(96%);
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 991px) {
  #container_footer {
    width: auto!important;
    margin-left: 0em!important;
    margin-right: 0em!important;
  }
  #container_footer .footer {
    padding-bottom: 20px;
  }
  #container_footer .grid > #bloc_logo {
    width: 100%!important;
    text-align: center;
  }
  #container_footer .grid > #bloc_liens_footer {
    top: 0;
    transform: none;
    position: initial;
    width: 100%!important;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0;
  }
  #container_footer .grid > #bloc_liens_footer > .grid #bloc_mentions {
    padding-bottom: 0px;
    padding-top: 0px;
    width: 100%!important;
    text-align: center;
  }
}
@media all and (max-width: 400px) {
  #container_footer #footer_base .grid > #bloc_logo {
    padding: 2rem 1rem;
  }
  #container_footer #footer_base .grid > #bloc_logo img {
    width: 75%;
    height: auto;
  }
}
/********************************[HEADER DE BASE : Blog]********************************/
header #bloc_header_menu.header_blog {
  background: #16374A;
  margin-right: 1rem;
  margin-left: 1rem;
  padding-bottom: 0;
}
header #bloc_header_menu.header_blog #blog_logo {
  padding: 0;
  padding-left: 50px;
}
header #bloc_header_menu.header_blog #blog_logo #logo img {
  padding: 10px 15px;
  background: rgba(0, 0, 28, 0.8);
  box-shadow: 0px 15px 10px -15px #111;
  position: absolute;
  z-index: 1;
}
header #bloc_header_menu.header_blog #blog_logo #menu {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 0;
}
header #bloc_header_menu.header_blog #blog_logo #menu > a {
  color: #FFF;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: -1px;
  text-decoration: none!important;
  margin-right: 5px;
  font-weight: 500;
}
header #bloc_header_menu.header_blog #blog_logo #menu > a:hover #lien_partie_blog {
  color: #2D4962;
  text-decoration: underline!important;
}
header #bloc_header_menu.header_blog #blog_logo #menu > a:hover #lien_partie_blog ~ i {
  color: #2D4962;
}
header #bloc_header_menu.header_blog #blog_logo #menu #count {
  background: #F69719;
  padding: 2px 5px;
  margin-left: 5px;
  border-radius: 2px;
  text-decoration: none!important;
}
header #bloc_header_menu.header_blog #blog_logo #menu #count.is-empty {
  background-color: #16374a;
}
header #bloc_header_menu.header_blog #bloc_maitre {
  background-size: 100% 100% !important;
  background-position: center center !important;
  background-color: #2D4962;
  padding: 35px;
  padding-left: 50px;
}
header #bloc_header_menu.header_blog #bloc_maitre #title {
  font-weight: 600!important;
  font-size: 40px;
  color: #FFFFFF;
  margin-bottom: 0px;
}
header #bloc_header_menu.header_blog #bloc_maitre #subtitle {
  font-size: 25px;
  font-weight: 500!important;
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 1200px) {
  #bloc_header_menu.header_blog {
    position: initial;
  }
  #bloc_header_menu.header_blog #bloc_maitre {
    padding-top: 35px!important;
  }
  #bloc_header_menu.header_blog #bloc_maitre #title {
    font-size: 36px!important;
  }
  #bloc_header_menu.header_blog #bloc_maitre #subtitle {
    font-size: 20px!important;
  }
}
@media all and (max-width: 1080px) {
  #bloc_header_menu.header_blog #blog_logo {
    padding-left: 1rem!important;
    margin-bottom: 4rem;
  }
  #bloc_header_menu.header_blog #blog_logo #menu {
    display: none;
  }
  #menu-container #blog_list_menu > li > a.responsive {
    font-size: 20px;
  }
}
@media all and (max-width: 575px) {
  header #bloc_header_menu.header_blog #bloc_maitre {
    background-size: cover!important;
  }
}
@media all and (max-width: 400px) {
  header #bloc_header_menu.header_blog #bloc_maitre {
    padding: 20px;
    padding-top: 20px!important;
  }
  header #bloc_header_menu.header_blog #bloc_maitre #title {
    font-size: 32px!important;
  }
}
/***************************[HEADER SI CONNECTÉ : Accueil]*********************/
header #bloc_header_menu #menu_header.user_lambda > .grid > .column {
  text-align: center;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .btn_blog {
  margin-bottom: 0;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil {
  margin-top: 1px!important;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown {
  background: #FFF;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu {
  left: auto;
  right: 0px!important;
  margin-top: 10px;
  border-radius: 0;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu .item {
  text-align: left;
  padding: .78571429rem 1.14285714rem!important;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu .item i {
  color: rgba(0, 0, 0, 0.87);
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu .item span {
  color: #FA980D;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1200px) {
  #menu_header.user_lambda > .grid > .column {
    display: block;
    text-align: right;
  }
  #menu_header.user_lambda > .grid > .column .btn_blog {
    padding-bottom: 0;
  }
}
@media all and (max-width: 1080px) {
  #menu_header.user_lambda {
    display: none;
  }
}
/***************************[HEADER DE BASE : Accueil]*******************/
header #bloc_header_menu #blog_logo {
  padding: 20px 40px;
}
header #bloc_header_menu #menu_header {
  margin-top: 15px;
  margin-right: 20px;
  padding-left: 0px;
  /**Si connexion user lambda**/
}
header #bloc_header_menu #menu_header.user_lambda {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  padding-right: 22px;
  z-index: 1;
  /**ie**/
  position: relative;
  /*****/
}
header #bloc_header_menu #menu_header.user_lambda > .grid {
  position: absolute;
  top: 50%;
  right: 5%;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .btn_blog {
  margin-bottom: 0;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil {
  margin-top: 0;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown {
  background: #FFF;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu {
  left: auto;
  right: 0.5rem!important;
  margin-top: 10px;
  border-radius: 0;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu .item {
  text-align: left;
  padding: .78571429rem 1.14285714rem!important;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu .item i {
  color: rgba(0, 0, 0, 0.87);
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu .item span {
  color: #FF880F;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu .item span.column {
  color: #3192C5;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu a {
  text-decoration: none;
}
header #bloc_header_menu #menu_header.user_lambda > .grid > .column .bloc_profil .inline.dropdown .menu a:hover span.column {
  color: #23527c;
  text-decoration: underline!important;
}
header #bloc_header_menu #menu_header .grid .column a {
  text-transform: uppercase;
  color: #3192C5;
  font-size: 12px;
  font-weight: 500;
}
header #bloc_header_menu #menu_header .grid .column a:hover {
  color: #23527c;
}
header #bloc_header_menu #menu_header .grid .column a > span {
  background-color: #FA980D;
  padding: 2px 3px;
  margin-left: 5px;
  color: #FFF;
}
header #bloc_header_menu #menu_header .grid .column .segment.header_connecte {
  margin: 0;
  padding: 0;
  margin-left: 1rem;
}
header #bloc_header_menu #menu_header .grid .column .segment.header_connecte .ui.inline.dropdown > .text {
  font-weight: 500!important;
}
header #bloc_header_menu #menu_header .grid .column .mon_blog {
  border: 1px solid #3192C5;
  padding: 5px 8px;
}
header #bloc_header_menu #menu_header .grid .column .mon_blog:hover {
  background: #3192C5;
  color: #FFF;
  text-decoration: none!important;
}
header #bloc_header_menu #menu_header .grid .column i.lock {
  margin-right: 5px;
}
header #bloc_header_menu #menu_header > .form .fields > .field input {
  border-radius: 0;
}
header #bloc_header_menu #menu_header > .form .fields > .field input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 0.7;
}
header #bloc_header_menu #menu_header > .form .fields > .field input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}
header #bloc_header_menu #menu_header > .form .fields > .field input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}
header #bloc_header_menu #menu_header > .form .fields > .field i {
  margin-right: 10px;
}
header #bloc_header_menu #menu_header > .form .fields > .field label {
  text-align: left;
  margin-bottom: 0.3rem;
  font-weight: lighter;
}
header #bloc_header_menu #menu_header > .form .fields > button[type="submit"] {
  background: #3192C5;
  border-radius: 0;
  height: 34.63px;
  transform: translateY(65%);
}
header #bloc_header_menu #menu_header > .form .fields > button[type="submit"]:hover {
  background: #FFF;
  border-radius: 0;
  outline: 1px solid #3192C5;
  outline-offset: -1px;
}
header #bloc_header_menu #menu_header > .form .fields > button[type="submit"]:hover i {
  color: #3192C5;
  opacity: 1;
}
header #bloc_header_menu #menu_header > .form .fields > button[type="submit"] i {
  color: #FFF;
  margin: 0;
}
header #bloc_header_menu #menu_header .menu_header-links {
  float: right;
  font-weight: 300;
}
header #bloc_header_menu #menu_header #password_forget:hover {
  text-decoration: underline;
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 1080px) {
  #bloc_header_menu {
    z-index: 1;
    background: #FFF;
    margin-left: 1em;
    margin-right: 1em;
  }
  #menu_header {
    display: none!important;
  }
}
@media all and (max-width: 500px) {
  #bloc_header_menu #blog_logo {
    padding: 20px;
    margin-right: 10px;
  }
  #bloc_header_menu #blog_logo > a > img {
    width: 205px;
    height: 48px;
  }
}
@media all and (max-width: 400px) {
  header #bloc_header_menu #blog_logo {
    padding: 20px;
  }
}
/**Accordion Thématiques**/
/********************************[THEMATIQUES : forum]*************************/
.accordion_thematique_top {
  width: 100%;
}
.accordion_thematique_top .container_accordion_thematiques {
  margin-top: -50px;
}
.container_accordion_thematiques {
  margin-top: -250px;
  position: relative;
  width: 100%;
  text-align: left;
}
.container_accordion_thematiques.forum {
  margin-top: -35px;
}
.container_accordion_thematiques .menu {
  border-radius: 0;
}
.articles .container_accordion_thematiques {
  margin-top: -50px;
}
.message_user_forum .container_accordion_thematiques {
  margin-top: -140px;
}
.container_accordion_thematiques > .accordion {
  background: #3192C5!important;
}
.container_accordion_thematiques > .accordion > .item #default {
  color: #fff;
  transition: all 0.25s ease-in;
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 500;
}
.container_accordion_thematiques > .accordion > .item #default:hover {
  color: #142334;
  text-decoration: none!important;
}
.container_accordion_thematiques > .accordion > .item > .content > .item {
  border-bottom: 1px solid rgba(170, 235, 255, 0.2);
  padding-left: 0;
  padding-right: 0;
}
.container_accordion_thematiques > .accordion > .item > .content > .item > .title {
  text-transform: inherit;
  color: #FFF;
  font-size: 13px;
  font-weight: 500;
}
.container_accordion_thematiques > .accordion > .item > .content > .item > .title:hover {
  color: #142334;
  text-decoration: none!important;
}
.container_accordion_thematiques > .accordion > .item > .content > .item > .content > .item > .content {
  padding: 13px;
  margin-left: 1rem;
}
.container_accordion_thematiques > .accordion > .item > .content > .item > .content > .item > .title {
  color: #b8e6ff;
  font-size: 12px;
  padding-top: 13px;
  font-weight: 500;
  margin-left: 1rem;
}
.container_accordion_thematiques > .accordion > .item > .content > .item > .content > .item > .title:hover {
  color: #142334;
  text-decoration: none!important;
  border-color: #b8e6ff;
}
.container_accordion_thematiques > .accordion > .item > .content > .item > .content > .item > .title.active {
  color: #FFF;
}
.container_accordion_thematiques > .accordion > .item > .content > .item > .content > .item > .content > .form > .fields > .field > a {
  color: #b8e6ff;
  font-weight: 500;
  font-size: 12px;
}
.container_accordion_thematiques > .accordion > .item > .content > .item > .content > .item > .content > .form > .fields > .field > a:hover {
  color: #142334;
  text-decoration: none!important;
  border-color: #b8e6ff;
}
.container_accordion_thematiques > .accordion > .item > .content > .item > .content > .item > .content > .form > .fields > .field > a.active {
  color: #FFF;
}
.container_accordion_thematiques > .accordion > .item > .content > .item > .content > .item > .content > .form > .fields > .field > a.active::before {
  content: "►";
}
.container_accordion_thematiques > .accordion > .item form {
  width: 100%;
}
.container_accordion_thematiques > .accordion > .item .input {
  margin-top: 10px;
  width: 100%;
  border-radius: 0;
}
.container_accordion_thematiques > .accordion > .item .input > input {
  font-size: 16px;
  background: none!important;
  border: none;
  border-bottom: 1px solid rgba(177, 214, 233, 0.61);
  padding-left: 0;
  color: #FFF;
  border-radius: 0;
}
.container_accordion_thematiques > .accordion > .item .input > input::placeholder {
  color: #FFF;
}
.container_accordion_thematiques > .accordion > .item .input > i {
  color: #FFF;
  opacity: 1;
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 1080px) {
  .container_accordion_thematiques {
    margin-top: 0rem!important;
    display: table-header-group;
    padding-right: 0!important;
    padding-left: 0!important;
    margin-bottom: 1rem;
  }
  .container_accordion_thematiques.forum {
    margin-bottom: 3rem;
    margin-top: 1rem!important;
  }
}
/**Bloc Droite**/
/*********************************[A Propos]***********************************/
#bloc_apropos {
  padding: 2rem;
}
#bloc_apropos .supprimer-image {
  display: block;
  text-align: center;
  color: red;
}
#bloc_apropos > .grid > .column {
  background: #2D4962;
  text-align: left;
}
#bloc_apropos > .grid > .column > span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
#bloc_apropos > #bloc_texte > .input {
  background: #EAF4F9;
}
#bloc_apropos > #bloc_texte > .input > .grid > #bloc_info {
  margin-bottom: 0;
  text-align: justify;
}
#bloc_apropos > #bloc_texte > .input > .grid > #bloc_info > i {
  padding-right: 10px;
  float: left;
  font-size: 30px;
}
#bloc_apropos > #bloc_texte img {
  margin: auto;
  width: 75%;
  height: auto;
}
/*******************************[RESPONSIVE]********************************/
@media all and (max-width: 767px) {
  #bloc_apropos > #bloc_texte img {
    width: 40%;
  }
}
/*****************************[BLOC CONSULTATION]******************************/
#bloc_consultation {
  padding: 2rem;
}
#bloc_consultation > .grid > .column {
  background: #2D4962;
  text-align: left;
}
#bloc_consultation > .grid > .column > span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
#bloc_consultation > .grid > #bloc_posez_question {
  background: #F7F8FA!important;
  box-shadow: 0px 15px 10px -15px #111;
}
#bloc_consultation > .grid > #bloc_posez_question button[name="posez_question"] {
  margin-top: 1rem;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > .column {
  padding: 0;
  text-align: center;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > .column > img {
  margin: auto;
  max-width: 100%;
  height: auto;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > .column > p {
  position: absolute;
  background: rgba(255, 255, 255, 0.79);
  bottom: 0;
  padding: 10px;
  width: 100%;
  font-size: 20px;
  color: #132C46;
  margin-bottom: 0px;
  font-weight: bold;
  text-align: center;
  word-break: break-word;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > a > .input > button {
  background-color: #3192C5;
  border: 1px solid #3192C5;
  color: #FFF;
  padding: 15px 50px;
  text-transform: uppercase;
  border-radius: 0px;
  margin: auto;
  margin-top: 10px;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > a > .input > button:hover {
  background-color: #FFF;
  border: 1px solid #3192C5;
  color: #3192C5;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > #bloc_reponse {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 25px;
  text-align: left;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > #bloc_reponse > .column {
  padding: 0;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > #bloc_reponse > .column #title_prix {
  text-transform: uppercase;
  font-size: 12px;
  border-bottom: 1px solid #DFE1E2;
  color: #142E46;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > #bloc_reponse > .column #prix {
  color: #2D4962;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > #bloc_reponse > .column #prix sup {
  font-size: 12px !important;
  top: -0.7em;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > #bloc_reponse > .column #reponse {
  font-size: 12px;
  color: #142E46;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > #bloc_reponse > .column #eval {
  color: #2D4962;
  font-size: 12px;
  font-weight: bold;
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > #bloc_reponse > .column #vote .star {
  margin: 0;
  color: rgba(0, 0, 0, 0.15);
}
#bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > #bloc_reponse > .column #vote .star.selected {
  color: #2D4962;
}
/*******************************[RESPONSIVE]********************************/
@media all and (max-width: 767px) {
  #bloc_consultation > .grid > #bloc_posez_question > .grid > .column > img {
    height: 216px;
  }
}
@media all and (max-width: 400px) {
  #bloc_consultation > .grid > #bloc_posez_question > .grid > #bloc_info > a > .input > button {
    font-size: 10px!important;
  }
}
/****************************[BLOC DROITE : THEMES]****************************/
#bloc_themes {
  padding: 2rem;
}
#bloc_themes > .grid > .column {
  background: #2D4962;
  text-align: left;
}
#bloc_themes > .grid > .column > span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
#bloc_themes > .grid > #bloc_texte {
  background: #EAF4F9;
  text-align: left;
}
#bloc_themes > .grid > #bloc_texte > .grid > ul {
  width: 100%;
  list-style: none;
  padding: 20px;
  font-weight: 300;
}
#bloc_themes > .grid > #bloc_texte > .grid > ul > li a {
  margin-left: 0.5rem;
}
#bloc_themes > .grid > #bloc_texte > .grid > ul > li a:hover {
  text-decoration: underline;
}
#bloc_themes > .grid > #bloc_texte > .grid > ul > li a:hover i {
  text-decoration: none;
}
/****************************[BLOC DROITE : TYPES]*****************************/
#bloc_types {
  padding: 2rem;
}
#bloc_types > .grid > .column {
  background: #2D4962;
  text-align: left;
}
#bloc_types > .grid > .column > span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
#bloc_types > .grid > #bloc_texte {
  background: #EAF4F9;
  text-align: left;
}
#bloc_types > .grid > #bloc_texte > .grid > ul {
  list-style: none;
  padding: 20px;
  font-weight: 300;
}
#bloc_types > .grid > #bloc_texte > .grid > ul > li > a {
  margin-left: 0.5rem;
}
#bloc_types > .grid > #bloc_texte > .grid > ul > li > a:hover {
  text-decoration: underline;
}
#bloc_types > .grid > #bloc_texte > .grid > ul > li > a:hover i {
  text-decoration: none;
}
/******************************[BLOC DATES]************************************/
#bloc_dates {
  padding: 2rem;
}
#bloc_dates > .grid > .column {
  background: #2D4962;
  text-align: left;
}
#bloc_dates > .grid > .column > span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
#bloc_dates > .grid > #bloc_texte {
  background: #EAF4F9;
  text-align: left;
}
#bloc_dates > .grid > #bloc_texte > .grid > ul {
  list-style: none;
  padding: 20px;
  font-weight: 300;
}
#bloc_dates > .grid > #bloc_texte > .grid > ul > li > a {
  margin-left: 0.5rem;
}
#bloc_dates > .grid > #bloc_texte > .grid > ul > li > a:hover {
  text-decoration: underline;
}
#bloc_dates > .grid > #bloc_texte > .grid > ul > li > a:hover i {
  text-decoration: none;
}
/**************************[BLOC PARTENAIRES]**********************************/
#bloc_partenaires {
  padding: 2rem;
}
#bloc_partenaires > .grid > .column {
  background: #2D4962;
  text-align: left;
}
#bloc_partenaires > .grid > .column > span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
#bloc_partenaires > .grid > #bloc_texte {
  background: #EAF4F9;
  text-align: left;
}
#bloc_partenaires > .grid > #bloc_texte > .grid > ul {
  list-style: none;
  padding: 20px;
  font-weight: 300;
}
#bloc_partenaires > .grid > #bloc_texte > .grid > ul > li > a {
  margin-left: 0.5rem;
}
#bloc_partenaires > .grid > #bloc_texte > .grid > ul > li > a:hover {
  text-decoration: underline;
}
#bloc_partenaires > .grid > #bloc_texte > .grid > ul > li > a:hover i {
  text-decoration: none;
}
/*****************************[BLOC COMMENTAIRES]******************************/
#bloc_commentaires {
  padding: 2rem;
}
#bloc_commentaires > .grid > .column {
  background: #2D4962;
  text-align: left;
}
#bloc_commentaires > .grid > .column > span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
#bloc_commentaires > .grid > #bloc_com {
  background: #EAF4F9;
  text-align: left;
}
#bloc_commentaires > .grid > #bloc_com > .grid > ul {
  list-style: none;
  padding: 20px;
}
#bloc_commentaires > .grid > #bloc_com > .grid > ul > li {
  margin-bottom: 10px;
  word-break: break-all;
}
#bloc_commentaires > .grid > #bloc_com > .grid > ul > li > a > p {
  margin-bottom: 0;
}
#bloc_commentaires > .grid > #bloc_com > .grid > ul > li p.bloc_com_titre {
  margin-bottom: 2px;
  color: #122437;
  font-weight: 600;
}
/****************************[BLOC DROITE : FLUX RSS]**************************/
#bloc_rss {
  padding: 2rem;
}
#bloc_rss > .grid > .column {
  background: #2D4962;
  text-align: left;
}
#bloc_rss > .grid > .column > i.fa-rss {
  color: #FFF;
}
#bloc_rss a.lien_rss {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
#bloc_rss a.lien_rss:hover {
  color: #FFF!important;
}
/******************************[BLOC LIBRE]************************************/
#bloc_libre {
  width: 100%;
  padding: 2rem;
}
#bloc_libre > .grid > .column {
  background: #2D4962;
  text-align: left;
}
#bloc_libre > .grid > .column > span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
#bloc_libre > #bloc_texte > .input {
  background: #EAF4F9;
}
#bloc_libre > #bloc_texte > .input > .grid > #bloc_info > i {
  padding-right: 10px;
  float: left;
  font-size: 30px;
}
#bloc_libre > #bloc_texte > .input > .grid > #bloc_info > p {
  margin-bottom: 0;
}
/****************************[BLOC DROITE : RECHERCHER]************************/
/**Page Administration blog**/
#bloc_rechercher {
  margin: 1rem;
}
#bloc_rechercher .input {
  width: 100%;
}
#bloc_rechercher .input > input {
  padding: 10px;
  border: 1px solid #66ABE7;
  font-size: 14px;
  color: #66ABE7;
  font-weight: bold;
  padding-right: 25px;
  border-radius: 0;
  border-right: none!important;
}
#bloc_rechercher .input > input::placeholder {
  color: #66ABE7;
  text-transform: uppercase;
  opacity: 0.5;
}
#bloc_rechercher .input > button {
  background: none;
  box-shadow: none;
  border: 1px solid #2E8AB9;
  border-left: none;
  outline: none;
}
#bloc_rechercher .input > button:hover i {
  color: #66ABE7;
}
#bloc_rechercher .input > button > i {
  background: none;
  color: #909090;
  -webkit-appearance: none;
  cursor: pointer;
}
/**Page Présentation blog**/
.container_btn_search input {
  border-radius: 0!important;
}
.container_btn_search .ui.input {
  width: 100%!important;
}
.container_btn_search .button {
  border-radius: 0!important;
  background: #3192C5;
  color: #FFF;
  margin-left: 0.1rem!important;
}
.container_btn_search .button:hover {
  background: #FFF;
  color: #3192C5;
  outline: 1px solid #3192C5 !important;
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 1080px) {
  .container_btn_search {
    display: block!important;
  }
  .container_btn_search.ui.fluid.input > input {
    width: 100%!important;
  }
  .container_btn_search button {
    display: block!important;
    width: 100%;
    margin-top: 1rem!important;
  }
}
/**************************[BLOC NEWSLETTER]***********************************/
#bloc_newsletter {
  width: 100%;
  padding: 2rem;
}
#bloc_newsletter .column {
  text-align: left;
}
#bloc_newsletter .column span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
#bloc_newsletter .column.titre {
  background: #2D4962;
}
#bloc_newsletter .column.bloc_texte {
  background: #EAF4F9;
}
#bloc_newsletter button {
  padding: 0 10px;
  height: 35px;
  border: none;
  background: #2D4962;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
/***************************[BLOC DROITE DANS LE BLOG]*************************/
.masonry.grid {
  display: flex;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1080px) {
  .masonry.grid {
    display: block;
    margin: auto;
  }
  .masonry.grid.posez_question {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
@media all and (min-width: 992px) {
  .masonry.grid {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .masonry.grid.forum {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    margin: auto;
  }
}
@media all and (min-width: 768px) {
  .masonry.grid {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
  }
  .ui.doubling.masonry.grid[class*="three column"] > .column {
    width: 100% !important;
    padding: 0;
  }
}
/**Elements**/
/*********************[BOUTON BASE : ex : btn devenez benevole]****************/
.button_blue {
  font-size: 12px!important;
  padding: 15px 50px 15px 50px!important;
  color: #fff!important;
  text-transform: uppercase!important;
  border-radius: 0!important;
  font-weight: 300!important;
}
.button_blue.admin_article {
  background-color: #3192c5!important;
  border-color: #3192c5!important;
  margin-top: 0!important;
  width: 100%;
}
.button_blue.base {
  background-color: #3192c5!important;
  border-color: #3192c5!important;
}
.button_blue.button_return {
  padding: 10px!important;
}
.button_blue i {
  background-color: transparent!important;
  color: #FFF!important;
  opacity: 1!important;
}
.button_blue:hover {
  background-color: #FFF!important;
  color: #3192C5!important;
  outline: 1px solid #3192C5!important;
}
.button_blue:hover i {
  color: #3192C5!important;
}
/********************************[BOUTON SUPPRIMER]********************************/
.button_wrong {
  font-size: 12px!important;
  padding: 15px 50px 15px 50px!important;
  color: #fff!important;
  text-transform: uppercase!important;
  border-radius: 0!important;
  background-color: #FF0000!important;
  border-color: #FF0000!important;
  margin-top: 0!important;
  font-weight: 300!important;
}
.button_wrong i {
  background-color: transparent!important;
  color: #FFF!important;
  opacity: 1!important;
}
.button_wrong:hover {
  background-color: #FFF!important;
  color: red!important;
  outline: 1px solid red!important;
}
.button_wrong:hover i {
  color: red!important;
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 768px) {
  .button_blue {
    width: 80%!important;
    margin-bottom: 10px!important;
  }
  .button_wrong {
    width: 80%!important;
    margin-bottom: 10px!important;
  }
}
@media all and (max-width: 400px) {
  .button_blue {
    width: 100%!important;
  }
  .button_wrong {
    width: 100%!important;
  }
}
/********************************[ETAPE]***************************************/
.etape .etape-images .menu .item {
  cursor: pointer;
}
.etape .etape-images .etape-images-container .etape-images-container-image {
  border: 2px solid #fff;
  cursor: pointer;
}
.etape .etape-images .etape-images-container .etape-images-container-image.is-selected {
  border: 2px solid red;
}
/***********************[Images uplaodées + API avec recadrage]****************/
.file-upload {
  background-color: #ffffff;
  margin: 0 auto;
}
.file-upload-btn {
  width: 100%;
  margin: 0 0 20px 0;
  color: #fff;
  background: #3192C5;
  border: none;
  padding: 10px;
  border-radius: 4px;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 600;
}
.file-upload-btn:hover {
  background: #FFF;
  color: #3192C5;
  outline: 1px solid #3192C5;
  transition: all .2s ease;
  cursor: pointer;
}
.file-upload-btn:active {
  border: 0;
  transition: all .2s ease;
}
.file-upload-content {
  display: none;
  text-align: center;
}
.file-upload-content,
.file-api-content {
  display: none;
  text-align: left;
}
#file-upload-input,
.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed #3192C5;
  position: relative;
  margin-bottom: 20px;
}
.image-upload-wrap .input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.image-dropping,
.image-upload-wrap:hover {
  border: 4px dashed #3192C5;
}
.image-dropping,
.image-upload-wrap:hover .drag-text h3 {
  color: #3192C5;
  font-size: 23px;
  text-transform: uppercase;
}
.image-title-wrap {
  padding: 15px;
  color: #222;
  text-align: center;
  word-break: break-all;
}
.drag-text {
  text-align: center;
}
.drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: #3192C5;
  padding: 60px 0;
}
.file-upload-image,
.file-api-image {
  max-width: 100%;
  margin: auto;
  padding: 20px;
}
.remove-image {
  margin: 0;
  color: red;
  border: none;
  padding: 10px;
  background: none;
}
.remove-image:hover {
  outline: 1px solid #c13b2a;
  color: red;
  transition: all .2s ease;
  cursor: pointer;
}
.remove-image:active {
  border: 0;
  transition: all .2s ease;
}
/********************************[INFORMATIONS.TPL]****************************/
.ui.toggle.checkbox {
  margin: 15px;
}
.informations {
  width: 300px;
  margin-top: 30px;
}
.informations > .grid {
  width: 300px;
}
.informations > .grid #bloc_apropos {
  width: 300px;
}
/************************************[LEADS]***********************************/
.section_leads h2 {
  margin-bottom: 1rem!important;
}
.section_leads .filter_leads {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  text-align: left;
  outline: 0;
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  background: #fff;
  display: inline-block;
  padding: .78571429em 2.1em .78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.section_leads .container_totaux_leads {
  display: block;
}
.section_leads .container_totaux_leads .print-ajax {
  display: flex;
  text-align: center;
}
.section_leads .container_totaux_leads .print-ajax .ui.label {
  background: #3192C5;
  color: #FFF;
  border-radius: 0!important;
  margin: auto!important;
  font-size: smaller;
}
.section_leads .container_totaux_leads .print-ajax .ui.label .detail {
  font-size: medium;
  margin: auto;
  margin-top: 0.2rem;
  display: block;
}
.section_leads .container_totaux_leads .print-ajax .ui.label .detail span {
  color: rgba(0, 0, 0, 0.87);
}
.section_leads .container_totaux_leads .print-ajax .ui.label .detail span.all {
  display: block;
}
.section_leads table thead tr {
  text-align: center;
}
.section_leads table tbody tr td:first-child {
  font-weight: 300!important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.section_leads table tbody tr td:nth-child(2) {
  font-weight: bold;
}
/*********************************[RESPONSIVE]*********************************/
@media all and (max-width: 991px) {
  .section_leads .container_totaux_leads .print-ajax {
    display: grid;
  }
  .section_leads .container_totaux_leads .print-ajax .ui.label {
    margin-bottom: 0.3rem!important;
    width: 100%;
  }
  .section_leads .container_totaux_leads .print-ajax .ui.label:last-child {
    margin-bottom: 0!important;
  }
}
@media all and (max-width: 767px) {
  .section_leads table thead {
    display: table-caption;
  }
  .section_leads table thead tr {
    font-size: 0.8rem;
  }
  .section_leads table thead tr th {
    border: 1px solid rgba(34, 36, 38, 0.1);
    border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
  }
  .section_leads table tbody tr {
    font-size: 0.8rem;
    display: grid;
  }
  .section_leads table tbody tr td {
    word-break: initial;
  }
}
/*************************[MESSAGE SUCCESS / WARNING]**************************/
.success.message,
.warning.message {
  margin-top: 0px;
}
/********************************[PAGINATION]***************************************/
.pagination {
  margin-top: 2rem!important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  padding: 0!important;
  margin-bottom: 1rem!important;
}
.pagination.articles_recents_blog {
  margin-top: 2rem!important;
}
.pagination #multipage {
  display: inherit;
}
.pagination #multipage a.item {
  padding-left: 0;
  padding-right: 0;
  display: block;
}
/********************************[RESPONSIVE]***************************************/
@media all and (max-width: 768px) {
  /****PAGINATION****/
  #container_pagination {
    text-align: center;
  }
  .pagination {
    margin-left: auto!important;
    margin-right: auto!important;
  }
  .pagination #multipage .item.is-number {
    display: none;
  }
  .pagination #multipage .item.active {
    display: block;
  }
}
/*********************************[TINYMCE]************************************/
body#tinymce {
  background: #fff !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  word-wrap: break-word;
  margin: 8px;
  height: auto;
  text-align: justify;
  /********************************[FORUM]********************************/
  /********************************[IS-ARTICLE]********************************/
  /********************************[IS-PAGE]********************************/
  /**THEME_AUTRE_BEIGE**/
  /**THEME_AUTRE_ORANGE**/
  /**THEME_AUTRE_VERT**/
  /**THEME_AUTRE_VIOLET**/
  /**THEME_BEIGE**/
  /**THEME_BLEU_GRIS**/
  /**THEME_BLEU**/
  /**THEME_FUSHIA**/
  /**THEME_GRIS**/
  /**THEME_JAUNE**/
  /**THEME_MARRON**/
  /**THEME_MAUVE**/
  /**THEME_NOIR**/
  /**THEME_ORANGE**/
  /**THEME_ROSE**/
  /**THEME_ROUGE**/
  /**THEME_VERT**/
  /**THEME_VIOLET**/
}
body#tinymce h2,
body#tinymce h3 {
  font-family: 'Montserrat', sans-serif !important;
  color: #3192c5;
  letter-spacing: -1px;
  line-height: 1.28571429em;
  margin: 1rem 0;
  font-weight: 600;
  padding: 0em;
}
body#tinymce h2 {
  font-size: 26px;
}
body#tinymce h3 {
  font-size: 20px;
}
body#tinymce h4 {
  font-size: 18px;
}
body#tinymce h5 {
  font-size: 16px;
}
body#tinymce h6 {
  font-size: 14px;
}
body#tinymce ul {
  list-style-type: disc;
}
body#tinymce ol {
  list-style-type: decimal;
}
body#tinymce ul,
body#tinymce ol {
  margin-bottom: 15px;
  padding-left: 20px;
  margin-left: 1rem !important;
}
body#tinymce p,
body#tinymce ul,
body#tinymce ol {
  margin: 0em 0em 1em;
}
body#tinymce strong {
  font-weight: 600;
}
body#tinymce .quoteStyle,
body#tinymce blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  padding: .8rem;
  margin: auto;
  margin-bottom: 1rem;
  font-style: italic;
}
body#tinymce .quoteStyle:before,
body#tinymce blockquote:before {
  display: inline-block;
  font-size: 20px;
}
body#tinymce .quoteStyle:after,
body#tinymce blockquote:after {
  display: inline-block;
  font-size: 20px;
}
body#tinymce a {
  text-decoration: none;
}
body#tinymce .is-fluo {
  background: #ffff00;
}
body#tinymce.forum {
  font-size: 14px;
  color: #132433;
  text-align: justify;
}
body#tinymce.forum blockquote,
body#tinymce.forum .quoteStyle {
  font-style: italic;
  padding: .8rem;
  width: 95%;
  margin: auto auto 10px auto;
  text-align: left;
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  vertical-align: baseline;
  background-color: #e8e8e8;
  background-image: none;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  border: 0 solid transparent;
  border-radius: .28571429rem;
  transition: background .1s ease;
  text-align: justify;
}
body#tinymce.is-article {
  text-align: justify;
}
body#tinymce.is-article blockquote::before {
  content: '';
}
body#tinymce.is-article blockquote::after {
  content: '';
}
body#tinymce.theme_autre_beige a {
  color: #D9C5A2;
}
body#tinymce.theme_autre_beige h2,
body#tinymce.theme_autre_beige h3,
body#tinymce.theme_autre_beige h4,
body#tinymce.theme_autre_beige h5,
body#tinymce.theme_autre_beige h6 {
  color: #A6977C;
}
body#tinymce.theme_autre_orange a {
  color: #D98F50;
}
body#tinymce.theme_autre_orange h2,
body#tinymce.theme_autre_orange h3,
body#tinymce.theme_autre_orange h4,
body#tinymce.theme_autre_orange h5,
body#tinymce.theme_autre_orange h6 {
  color: #D98F50;
}
body#tinymce.theme_autre_vert a {
  color: #6B7A47;
}
body#tinymce.theme_autre_vert h2,
body#tinymce.theme_autre_vert h3,
body#tinymce.theme_autre_vert h4,
body#tinymce.theme_autre_vert h5,
body#tinymce.theme_autre_vert h6 {
  color: #6B7A47;
}
body#tinymce.theme_autre_violet a {
  color: #3C2535;
}
body#tinymce.theme_autre_violet h2,
body#tinymce.theme_autre_violet h3,
body#tinymce.theme_autre_violet h4,
body#tinymce.theme_autre_violet h5,
body#tinymce.theme_autre_violet h6 {
  color: #3C2535;
}
body#tinymce.theme_beige a {
  color: #b09671;
}
body#tinymce.theme_beige h2,
body#tinymce.theme_beige h3,
body#tinymce.theme_beige h4,
body#tinymce.theme_beige h5,
body#tinymce.theme_beige h6 {
  color: #b09671;
}
body#tinymce.theme_bleu_gris a {
  color: #355360;
}
body#tinymce.theme_bleu_gris h2,
body#tinymce.theme_bleu_gris h3,
body#tinymce.theme_bleu_gris h4,
body#tinymce.theme_bleu_gris h5,
body#tinymce.theme_bleu_gris h6 {
  color: #355360;
}
body#tinymce.theme_bleu a {
  color: #2C8CBE;
}
body#tinymce.theme_bleu h2,
body#tinymce.theme_bleu h3,
body#tinymce.theme_bleu h4,
body#tinymce.theme_bleu h5,
body#tinymce.theme_bleu h6 {
  color: #16374A;
}
body#tinymce.theme_fushia a {
  color: #5a1422;
}
body#tinymce.theme_fushia h2,
body#tinymce.theme_fushia h3,
body#tinymce.theme_fushia h4,
body#tinymce.theme_fushia h5,
body#tinymce.theme_fushia h6 {
  color: #5a1422;
}
body#tinymce.theme_gris a {
  color: #515151;
}
body#tinymce.theme_gris h2,
body#tinymce.theme_gris h3,
body#tinymce.theme_gris h4,
body#tinymce.theme_gris h5,
body#tinymce.theme_gris h6 {
  color: #515151;
}
body#tinymce.theme_jaune a {
  color: #9c823d;
}
body#tinymce.theme_jaune h2,
body#tinymce.theme_jaune h3,
body#tinymce.theme_jaune h4,
body#tinymce.theme_jaune h5,
body#tinymce.theme_jaune h6 {
  color: #9c823d;
}
body#tinymce.theme_marron a {
  color: #815a43;
}
body#tinymce.theme_marron h2,
body#tinymce.theme_marron h3,
body#tinymce.theme_marron h4,
body#tinymce.theme_marron h5,
body#tinymce.theme_marron h6 {
  color: #815a43;
}
body#tinymce.theme_mauve a {
  color: #745f6a;
}
body#tinymce.theme_mauve h2,
body#tinymce.theme_mauve h3,
body#tinymce.theme_mauve h4,
body#tinymce.theme_mauve h5,
body#tinymce.theme_mauve h6 {
  color: #745f6a;
}
body#tinymce.theme_noir a {
  color: #ef6a6a;
}
body#tinymce.theme_noir h2,
body#tinymce.theme_noir h3,
body#tinymce.theme_noir h4,
body#tinymce.theme_noir h5,
body#tinymce.theme_noir h6 {
  color: #1d1d1d;
}
body#tinymce.theme_orange a {
  color: #a55a16;
}
body#tinymce.theme_orange h2,
body#tinymce.theme_orange h3,
body#tinymce.theme_orange h4,
body#tinymce.theme_orange h5,
body#tinymce.theme_orange h6 {
  color: #a55a16;
}
body#tinymce.theme_rose a {
  color: #965675;
}
body#tinymce.theme_rose h2,
body#tinymce.theme_rose h3,
body#tinymce.theme_rose h4,
body#tinymce.theme_rose h5,
body#tinymce.theme_rose h6 {
  color: #965675;
}
body#tinymce.theme_rouge a {
  color: #B90033;
}
body#tinymce.theme_rouge h2,
body#tinymce.theme_rouge h3,
body#tinymce.theme_rouge h4,
body#tinymce.theme_rouge h5,
body#tinymce.theme_rouge h6 {
  color: #B90033;
}
body#tinymce.theme_vert a {
  color: #364422;
}
body#tinymce.theme_vert h2,
body#tinymce.theme_vert h3,
body#tinymce.theme_vert h4,
body#tinymce.theme_vert h5,
body#tinymce.theme_vert h6 {
  color: #364422;
}
body#tinymce.theme_violet a {
  color: #370037;
}
body#tinymce.theme_violet h2,
body#tinymce.theme_violet h3,
body#tinymce.theme_violet h4,
body#tinymce.theme_violet h5,
body#tinymce.theme_violet h6 {
  color: #370037;
}
/***************************[MESSAGE OK]***************************************/
.msgbox #msgbox_ok {
  background: #3192C5;
}
/******************************[ADS GOOGLE]************************************/
.adsbygoogle {
  display: block !important;
  margin: 0 auto;
  width: 100%;
  background: #eee;
  border: 1px solid #000;
}
.adsbygoogle.is-pub-right {
  margin-top: 15px;
  height: 250px;
}
.adsbygoogle.is-leaderboard {
  margin-top: 15px;
  height: 90px;
}
/****************************[ORGANISATION]************************************/
.organization {
  display: inline-block;
  padding: 20px;
  background-image: url(/ressources/images/background_defaut_header_presentation.png);
  color: #fff;
}
.organization.is-full {
  display: block;
  margin-bottom: 20px;
}
.organization .organization-name {
  display: block;
  margin-bottom: 15px;
  font-size: 1.1rem;
  font-weight: 600;
}
.organization .organization-street {
  margin-bottom: 5px;
}
.organization .organization-zip {
  margin: 0 0 10px 25px;
}
/**Menu**/
/*******************************[MENU LEFT ex: Messagerie]********************************/
#bloc_menu_left.menu_administrateur {
  margin-top: 3rem;
}
#bloc_menu_left > .grid .menu {
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}
#bloc_menu_left > .grid .menu > a > .label {
  background: #3192c5!important;
}
#bloc_menu_left .item #bloc_search {
  width: 100%;
}
/*******************************[MENU LEFT RESPONSIVE ex: Messagerie]********************************/
#accordion_infos {
  display: none;
  margin-bottom: 50px;
  margin-left: -1rem;
  margin-right: -1rem;
  border-radius: 0;
}
#accordion_infos .ui.label {
  margin-left: 5px!important;
  background: #3192c5!important;
  color: #FFF!important;
}
/*******************************[RESPONSIVE]********************************/
@media all and (max-width: 1200px) {
  #bloc_menu_left {
    width: 100%!important;
    margin-bottom: 20px;
  }
  #bloc_menu_left.bloc_infos_perso {
    display: none;
  }
  #bloc_menu_left > .grid .menu {
    width: 100%;
  }
  #accordion_infos {
    display: block;
  }
}
/********************************[MENU RESPONSIVE]********************************/
#container_menu_hamburger {
  z-index: 1000;
}
#menu-wrapper {
  overflow: hidden;
  cursor: pointer;
  display: none;
}
#menu-wrapper #hamburger-menu {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.4s ease-in-out;
}
#menu-wrapper #hamburger-menu span {
  opacity: 1;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 10px;
  color: black;
  background-color: #3192C5;
  position: absolute;
  transform: rotate(0deg);
  transition: 0.4s ease-in-out;
}
#menu-wrapper #hamburger-menu span:nth-child(1) {
  top: 0;
}
#menu-wrapper #hamburger-menu span:nth-child(2) {
  top: 9px;
}
#menu-wrapper #hamburger-menu span:nth-child(3) {
  top: 18px;
}
#menu-wrapper #hamburger-menu.open span:nth-child(1) {
  top: 9px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  background-color: #FFF;
}
#menu-wrapper #hamburger-menu.open span:nth-child(2) {
  opacity: 0;
}
#menu-wrapper #hamburger-menu.open span:nth-child(3) {
  top: 9px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  background-color: #FFF;
}
#menu-container #blog_list_menu {
  display: block;
  padding-top: 55px;
  padding-bottom: 55px;
  font-weight: 600!important;
}
#menu-container #blog_list_menu > li {
  padding: 10px 40px;
}
#menu-container #blog_list_menu > li > a {
  color: #FFF;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600!important;
}
#menu-container #blog_list_menu > li > a.active {
  color: #66ABE7;
}
#menu-container #blog_list_menu > li:hover > a {
  color: #23527c;
  text-decoration: underline;
}
#menu-container #blog_list_menu > li:hover > a.active {
  color: #66ABE7;
}
#menu-container #blog_list_menu > #divider {
  border: 1px solid #D4E8F5;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#menu-container #blog_list_menu > #bloc_deconnexion {
  padding: 10px 40px;
}
#menu-container #blog_list_menu > #bloc_deconnexion a {
  display: block;
  color: #FFF;
  font-size: 20px;
  margin-bottom: 0.5rem;
}
#menu-container #blog_list_menu > #bloc_deconnexion a:hover {
  color: #23527c;
}
#menu-container #blog_list_menu > #bloc_deconnexion > .form > .field > .fields > button[type="submit"] {
  background: none;
}
#menu-container #blog_list_menu > #bloc_deconnexion > .form > .field > .fields > button[type="submit"] {
  color: #FFF;
}
#menu-container #blog_list_menu > #bloc_deconnexion a.forget_password {
  font-size: 10px;
  margin-bottom: 5px;
}
#menu-container #blog_list_menu > #bloc_deconnexion #container_connexion_responsive .segment {
  box-shadow: none;
  border-radius: 0;
  border: none;
  margin-bottom: 2rem;
  background: none;
}
#menu-container #blog_list_menu > #bloc_deconnexion #container_connexion_responsive .segment .input input {
  border-radius: 0;
}
#menu-container #blog_list_menu > #bloc_deconnexion #container_connexion_responsive .segment button {
  border-radius: 0;
}
#menu-container #blog_list_menu > #bloc_deconnexion #container_connexion_responsive #button_search_responsive {
  margin-top: 1rem;
}
#menu-container #blog_list_menu > #bloc_deconnexion #container_connexion_responsive .password-reset-link {
  color: #fff;
  margin-top: 10px;
  font-size: 12px;
}
#menu-container #blog_list_menu #recherche_responsive_blog {
  margin-top: 2rem!important;
  padding: 10px;
}
#menu-container .menu-list {
  padding-left: 0;
  display: block!important;
  height: 100%;
  position: fixed;
  top: 0;
  margin: 0;
  width: 100%;
  background: #3192C5;
  box-shadow: rgba(100, 100, 100, 0.2) 6px 2px 10px;
  z-index: 999;
  overflow-y: auto;
  overflow-x: hidden;
  left: -200%;
  list-style: none;
}
#menu-container .menu-list .menu-submenu {
  padding-top: 20px;
  padding-bottom: 20px;
  list-style: none;
  background: #FFF;
  padding-left: 60px;
  height: auto;
  max-height: 400px;
  overflow-y: scroll;
  font-weight: 500;
}
#menu-container .menu-list .menu-submenu > li {
  padding: 5px;
}
#menu-container .menu-list .menu-submenu > li > a {
  color: #66ABE7;
}
#menu-container .menu-list .menu-submenu > li > a:hover {
  color: #23527c;
}
#menu-container .menu-list li.accordion-toggle {
  font-size: 16px;
  text-transform: uppercase;
}
#menu-container .menu-list li:first-of-type {
  border-top: 0;
}
#menu-container .menu-list .menu-login {
  font-size: 16px;
  text-transform: uppercase;
}
#menu-container .menu-list .rightmenu {
  width: 94%;
  display: block;
  float: none;
  border-right: none;
  background-color: transparent;
  position: relative;
  white-space: inherit;
  margin: 0;
}
#menu-container .menu-list .rightmenu > form > input {
  border-radius: 0px;
  height: 44px;
  background-color: transparent;
  border-bottom: 1px solid #D4E8F5;
  font-size: 14px;
  padding-right: 35px;
  padding-left: 10px;
  width: 100%;
  border-left: none;
  border-top: none;
  border-right: none;
  color: #FFF;
}
#menu-container .menu-list .rightmenu > form > input::placeholder {
  color: #FFF;
}
#menu-container .menu-list .rightmenu > form > button {
  height: 44px;
  top: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  line-height: 30px;
  cursor: pointer;
  opacity: 1;
  color: #fff;
  z-index: 1000;
  background-color: transparent;
  border: solid 0px;
  transition: all 0.7s ease 0s;
  transform: translateY(-50%);
}
.accordion-toggle {
  cursor: pointer;
  font-size: 16px;
  position: relative;
  letter-spacing: 1px;
}
.accordion-toggle.active-tab {
  background: #23527c;
  transition: all 0.3s ease;
}
.accordion-toggle a::before,
.accordion-toggle a::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 30px;
  width: 15px;
  height: 2px;
  margin-top: -1px;
  background-color: #FFF;
  transform-origin: 50% 50%;
  transition: all 0.3s ease-out;
}
.accordion-toggle a::before {
  transform: rotate(-90deg);
  opacity: 1;
  z-index: 2;
}
.accordion-toggle a.active::before {
  transform: rotate(0deg);
  background: #fff !important;
}
.accordion-toggle a.active::after {
  transform: rotate(180deg);
  background: #fff !important;
  opacity: 0;
}
.accordion-content {
  cursor: pointer;
  font-size: 16px;
  position: relative;
  letter-spacing: 1px;
  display: none;
}
/********************************[RESPONSIVE]********************************/
@media all and (min-width: 1081px) {
  #container_menu_hamburger {
    display: none;
  }
  #menu-wrapper {
    display: none;
  }
}
@media all and (max-width: 1080px) {
  #container_menu_hamburger {
    margin: auto;
    margin-right: 2rem;
  }
  #container_menu_hamburger #menu-container #menu-wrapper > .open {
    position: fixed;
    background: #3192c5;
  }
  #menu-wrapper {
    display: block;
  }
  #menu-wrapper #hamburger-menu {
    float: right;
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 1000;
    transition: all 0.4s ease-in-out;
    padding: 18px;
  }
  #menu-wrapper #hamburger-menu span {
    background-color: #3193C6;
    margin-top: 8px;
    margin-left: 5px;
    width: 70%;
  }
  #menu-wrapper #hamburger-menu span.blog {
    background-color: #FFF;
  }
}
@media all and (max-width: 400px) {
  #menu-container #blog_list_menu > li > a {
    font-size: 16px;
  }
  .accordion-toggle,
  .accordion-content {
    font-size: 14px;
  }
  #container_menu_hamburger {
    margin-right: 1rem;
  }
}
/********************************[MENU]********************************/
#container_menu_principal {
  width: 100%;
  margin-top: 14px;
  background: #2E8AB9;
  margin-bottom: 1em;
}
#menu_principal {
  background: #3192C5;
  display: table;
  border: none;
  width: 100%;
  border-radius: 0;
}
#menu_principal > a {
  display: table-cell;
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin: auto;
  border: none;
  border-radius: 0;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  text-align: center;
  padding: 15px!important;
  word-break: break-word !important;
}
#menu_principal > a.home {
  width: 9%;
}
#menu_principal > a:hover {
  background-color: #FFF;
  color: #3192C5;
  outline: 2px solid #3192C5;
  outline-offset: -2px;
}
#menu_principal .mega-dropdown {
  margin: auto;
  display: table-cell;
  vertical-align: middle;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  padding-left: 12px;
}
#menu_principal .mega-dropdown:hover {
  background-color: #FFF;
  outline: 2px solid #3192C5;
  outline-offset: -2px;
}
#menu_principal .mega-dropdown:hover .thematiques {
  color: #3192C5;
}
#menu_principal .mega-dropdown:hover .item {
  background: none;
}
#menu_principal .mega-dropdown .thematiques {
  display: table-cell;
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin: auto;
  padding: 0px;
}
#menu_principal .mega-dropdown .thematiques::before {
  width: 0!important;
  background: none!important;
}
#menu_principal .mega-dropdown .megamenu {
  background-color: #FFF;
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 0px;
  padding-bottom: 19px;
  display: none!important;
  z-index: 2;
  top: auto;
  margin-top: 19px;
  border-bottom: 5px solid #3192C5;
}
#menu_principal .mega-dropdown .megamenu > .column {
  margin-left: 0px;
  margin-right: 0px;
}
#menu_principal .mega-dropdown .megamenu > .column > .column {
  box-shadow: none!important;
}
#menu_principal .mega-dropdown .megamenu > .column > .column #bloc_image {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  padding: 1.5rem;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list {
  margin-top: 10px;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list > ul {
  -moz-column-count: 4;
  -webkit-column-count: 4;
  column-count: 4;
  padding-right: 10px;
  list-style: none;
  padding-left: 0px;
  height: auto;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list > ul.ul_blog {
  -moz-column-count: 1;
  -webkit-column-count: 1;
  column-count: 1;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list > ul.list_with_images {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list > ul > li {
  display: inline-block;
  width: 100%;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list > ul > li:hover a {
  color: #23527c;
  border-bottom: 1px solid #23527c;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list > ul > li > a {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #D4E8F5;
  justify-content: left;
  color: #3192C5;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  min-height: 50px;
  word-break: break-word;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list > ul > li > a.a_blog {
  justify-content: center;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list > ul > li.list_duo_img {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list > ul.list_with_images {
  width: 100%;
  float: left;
  position: relative;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list .li_img_menu {
  display: block;
  height: 100%;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list .container_img_menu {
  float: right;
  position: relative;
  margin: auto;
  display: inline-grid;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list .container_img_menu.one_img {
  height: inherit;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list .container_img_menu.multi_img {
  width: 28%;
  height: 250px;
}
#menu_principal .mega-dropdown .megamenu > .column > .column > .list .container_img_menu a:hover img {
  opacity: 0.8;
}
#menu_principal .mega-dropdown:hover > .megamenu {
  display: block!important;
}
#menu_principal .ui.menu {
  border-radius: 0;
  box-shadow: none;
}
#menu_principal .ui.menu .item::before {
  width: 0!important;
  background: none!important;
}
#menu_principal .ui.menu .menu {
  border-bottom: none;
}
/********************************[MENU BLOG]********************************/
#container_menu_principal #menu_principal.container_menu_blog {
  border: none!important;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog {
  background: #3192C5;
  border: none!important;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .item.blog {
  display: inline-table;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 0;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  line-height: inherit!important;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .item.blog:hover {
  color: #3192C5;
  background: #FFF;
  outline: 2px solid #3192C5;
  outline-offset: -2px;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .item.blog.dropdown:hover {
  outline: none;
  box-shadow: 0 -2px 0 #3192C5;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .item.blog > a {
  color: #FFF!important;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .item.blog > a:hover {
  color: #3192C5!important;
  text-decoration: none!important;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .item.blog.home {
  height: 43px;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .item.blog.home i {
  margin: auto;
  margin-top: 3px;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog a.home {
  width: 9%;
  text-align: center;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog a.home.blog {
  width: 70px;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .ui.dropdown i.dropdown {
  margin-top: 3px;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .ui.dropdown:hover a {
  color: #3192C5!important;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .ui.dropdown .menu {
  border-top: none;
  border-bottom: 5px solid #3192C5;
  background: #FFF!important;
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .ui.dropdown .menu > .item {
  background: none!important;
  padding: 0.2rem!important;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .ui.dropdown .menu > .item > a.item {
  font-weight: 300;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .ui.dropdown .menu > .item:hover {
  color: #3192C5!important;
  background: rgba(0, 0, 0, 0.05) !important;
}
#container_menu_principal #menu_principal .ui.menu#menu_blog .ui.dropdown .menu > .item:hover a {
  color: #23527c!important;
  background: none!important;
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 1200px) {
  /****MENU****/
  #menu_principal .mega-dropdown .thematiques,
  #menu_principal > a {
    font-size: 12px;
  }
}
@media all and (max-width: 1080px) {
  #menu_principal {
    display: none!important;
  }
}
/**Pub**/
/********************************[PUB: Avoloi]*********************************/
/**SUJET FORUM**/
/***Dans le contenu***/
.container_avoloi_sujet {
  background: #000000!important;
  padding: 0.5rem!important;
}
.container_avoloi_sujet:hover {
  opacity: 0.8;
}
.container_avoloi_sujet:hover a {
  text-decoration: none!important;
}
.container_avoloi_sujet .ui.header {
  color: #FFF!important;
  margin: auto;
}
.container_avoloi_sujet .ui.header > img {
  width: 2.9em!important;
  margin-top: -0.95rem !important;
  background: #000;
  float: left;
  display: table-cell !important;
  opacity: 1;
  padding: 0.5rem;
  vertical-align: middle!important;
}
.container_avoloi_sujet .ui.header > .content {
  padding-left: .75rem;
  display: table-cell;
  vertical-align: middle;
}
.container_avoloi_sujet .ui.header > .content > .sub.header {
  font-weight: 500!important;
  color: #FFF!important;
  text-align: left;
  font-size: 0.8rem!important;
}
.container_avoloi_sujet .ui.header > .content > .sub.header span {
  color: #3192C5;
}
/***Dans la sidebar***/
.container_sidebar_avoloi {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.container_sidebar_avoloi.home {
  margin-bottom: 1rem;
  margin-top: 0rem;
}
.container_sidebar_avoloi .ui.image.consulter {
  background: url(/ressources/images/avoloi/bg_consulter2.jpg);
  height: 200px;
  background-position: center!important;
  background-size: cover!important;
}
.container_sidebar_avoloi .ui.image.consulter.home {
  height: 130px;
}
.container_sidebar_avoloi .ui.image .word_on_img {
  position: absolute;
  bottom: 0;
  padding: 1rem;
  margin: 1rem;
  background: #FA980D;
  font-weight: 700;
  color: #FFF;
}
.container_sidebar_avoloi .content {
  padding: 2rem;
  background: #f7f8fa;
}
.container_sidebar_avoloi .content.center {
  text-align: center;
}
.container_sidebar_avoloi .content .header {
  font-weight: 500;
}
.container_sidebar_avoloi .content .header.sub {
  color: #605f5a;
  font-weight: 300;
  font-size: .9rem;
}
/**PAGE AVOLOI FORM**/
/*Présentation avoloi*/
.container_pres_avoloi {
  border-bottom: 0!important;
}
.container_pres_avoloi .container h1 {
  color: #192a45;
}
.container_pres_avoloi .container .column:first-child {
  padding-left: 0!important;
}
.container_pres_avoloi .container .column:last-child {
  padding-right: 0!important;
}
.container_pres_avoloi .container .column:last-child p b {
  color: #3192C5;
}
.container_pres_avoloi .container .overlay_text {
  margin-bottom: 2rem;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #3192C5;
  padding: 1rem 2rem;
  font-size: 1.3rem;
  opacity: 0.92;
  font-weight: bold;
}
.container_pres_avoloi .container .overlay_text p {
  color: #FFF;
  font-weight: 400;
}
/*Formulaire avoloi*/
.container_form_avoloi {
  margin-top: 2rem;
}
.container_form_avoloi .card {
  margin-top: 2rem;
}
.container_form_avoloi .card form input {
  border-radius: 0!important;
}
.container_form_avoloi .card form .ui.dropdown {
  border-radius: 0!important;
}
/*Cgu Avoloi*/
.cgu_avoloi {
  padding-right: 20px;
  text-align: justify;
  margin-bottom: 20px;
  font-size: 10px;
  max-height: 100px;
  overflow-y: scroll;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1200px) {
  .ui.grid > [class*="seven wide"].column.section_avoloi_pub1 {
    width: 100%!important;
    height: 53px;
  }
  .ui.grid > [class*="nine wide"].column.section_btn_poser_question {
    width: 100%!important;
  }
  .container_avoloi_sujet .ui.header img {
    margin-top: -1.4rem !important;
  }
}
@media all and (max-width: 1080px) {
  #bloc_pubs .container_sidebar_avoloi.home {
    display: block;
  }
  .container_pres_avoloi .ui.relaxed.grid.container {
    width: 100%!important;
    margin: auto!important;
  }
  .container_pres_avoloi .ui.relaxed.grid.container .column {
    display: block;
    width: 100%;
    margin: auto;
    padding-left: 0;
  }
  .container_pres_avoloi .ui.relaxed.grid.container .column:first-child {
    padding-top: 0!important;
  }
  .container_pres_avoloi .ui.relaxed.grid.container .column img {
    height: 200px;
  }
  .container_pres_avoloi .ui.relaxed.grid.container .column .overlay_text {
    width: 100%;
  }
}
@media all and (max-width: 526px) {
  .container_avoloi_sujet .ui.header img {
    margin-top: -0.9rem !important;
  }
}
@media all and (max-width: 330px) {
  .container_avoloi_sujet .ui.header img {
    display: none!important;
  }
}
/**********************************[Pub EZOIC]*********************************/
.ui.ad {
  display: block;
  overflow: hidden;
  margin: 1em 0;
}
.ui.ad div[id^="google_ads_iframe"] {
  width: 100%!important;
}
.ui.ad div[id^="google_ads_iframe"] iframe {
  margin: auto;
  display: block;
}
.ui.ad div[id^="google_ads_iframe"] iframe body.jar {
  text-align: center;
}
.ui.ad.forum_top {
  margin-top: 3rem !important;
  margin-bottom: 0;
}
.ui.ad:first-child {
  margin: 0;
}
.ui.ad:last-child {
  margin: 0;
}
.ui.ad iframe {
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
}
.ui.leaderboard.ad {
  width: 728px;
  height: 100px;
}
.ui[class*="medium rectangle"].ad {
  width: 300px;
  height: 250px;
}
.ui[class*="large rectangle"].ad {
  width: 336px;
  height: auto;
}
.ui[class*="half page"].ad {
  width: 300px;
  height: 600px;
}
.ui.square.ad {
  width: 250px;
  height: 250px;
}
.ui[class*="small square"].ad {
  width: 200px;
  height: 200px;
}
.ui[class*="small rectangle"].ad {
  width: 180px;
  height: 150px;
}
.ui[class*="vertical rectangle"].ad {
  width: 240px;
  height: 400px;
}
.ui.button.ad {
  width: 120px;
  height: 90px;
}
.ui[class*="square button"].ad {
  width: 125px;
  height: 125px;
}
.ui[class*="small button"].ad {
  width: 120px;
  height: 60px;
}
.ui.skyscraper.ad {
  width: 120px;
  height: 600px;
}
.ui[class*="wide skyscraper"].ad {
  width: 160px;
}
.ui.banner.ad {
  width: 468px;
  height: 60px;
}
.ui[class*="vertical banner"].ad {
  width: 120px;
  height: 240px;
}
.ui[class*="top banner"].ad {
  width: 930px;
  height: 180px;
}
.ui[class*="half banner"].ad {
  width: 234px;
  height: 60px;
}
.ui[class*="large leaderboard"].ad {
  width: 970px;
  height: 90px;
}
.ui.billboard.ad {
  width: 970px;
  height: 250px;
}
.ui.panorama.ad {
  width: 980px;
  height: 120px;
}
.ui.netboard.ad {
  width: 580px;
  height: 400px;
}
.ui[class*="large mobile banner"].ad {
  width: 320px;
  height: 100px;
}
.ui[class*="mobile leaderboard"].ad {
  width: 320px;
  height: 50px;
}
.ui.mobile.ad {
  display: none;
}
@media all and (max-width: 1200px) {
  .ui.ad {
    width: 100%!important;
  }
}
@media all and (max-width: 400px) {
  .ui.ad div[id^="google_ads_iframe"] iframe {
    width: 100%;
  }
}
/********************************[DEVENEZ BENEVOLE : index]********************************/
#bloc_pubs .bloc_benevole_texte {
  margin-top: 20px;
}
#bloc_pubs > .grid #bloc_pub_benevole .bloc_benevole {
  position: relative;
}
#bloc_pubs > .grid #bloc_pub_benevole .bloc_benevole_texte {
  position: initial;
  top: initial;
  transform: none;
  left: initial;
  margin-top: 0px;
  background: #F7F8FA;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
#bloc_pubs > .grid #bloc_pub_benevole .bloc_benevole_texte #title {
  font-size: 20px;
  color: #3192C5;
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0px;
  margin-bottom: 10px;
  font-weight: 600!important;
}
#bloc_pubs > .grid #bloc_pub_benevole .bloc_benevole_texte #sub_title {
  font-size: 12px;
  color: #BDBDBD;
  text-transform: uppercase;
}
#bloc_pubs > .grid #bloc_pub_benevole .bloc_benevole_texte #text {
  font-size: 16px;
  color: #BDBDBD;
  padding-left: 30px;
  padding-right: 30px;
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 1080px) {
  #bloc_pubs .bloc_benevole {
    height: auto!important;
  }
  #bloc_pubs .bloc_benevole_texte {
    position: inherit!important;
    top: inherit!important;
    transform: inherit!important;
    left: 0px;
    width: 100%;
    margin-top: 40px;
  }
}
/********************************[CONSULTEZ UN AVOCAT : forum/message]********************************/
/**Title**/
#bloc_pubs > .grid .bloc_consultez {
  position: relative;
}
#bloc_pubs > .grid .bloc_consultez_texte {
  position: initial;
  top: initial;
  transform: none;
  left: initial;
  padding-top: 10px;
  background: #F7F8FA;
  text-align: center;
  padding-bottom: 1rem;
}
#bloc_pubs > .grid .bloc_consultez_texte #title {
  font-size: 20px;
  color: #3192C5;
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0px;
  margin-bottom: 0px;
  font-weight: 600!important;
}
#bloc_pubs > .grid .bloc_consultez_texte #lien_conseil {
  font-size: 12px;
  color: #F99F1E;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: bold;
}
#bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat {
  padding: 30px;
}
#bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat img {
  border: 3px solid #3192C5;
}
#bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content {
  text-align: left;
}
#bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content p#nom_avocat {
  text-transform: uppercase;
  font-weight: 500;
}
#bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content .description i.star.selected {
  color: #3192C5;
}
#bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content .description #bloc_prix_consulter #prix span {
  background: #000;
  padding: 5px 10px;
  color: #FFF;
  margin-right: 5px;
}
#bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content .description #bloc_prix_consulter #consulter {
  text-decoration: none!important;
}
#bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content .description #bloc_prix_consulter #consulter span {
  background: #3192C5;
  padding: 5px 10px;
  color: #FFF;
}
#bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content .description #bloc_prix_consulter #consulter span:hover {
  outline: 1px solid #3192C5;
  color: #3192C5;
  background: #FFF;
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 1200px) {
  /****BLOC DROITE****/
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat > .grid > #bloc_info > #bloc_avis #vote {
    width: 100%!important;
    padding-bottom: 0;
  }
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat > .grid > #bloc_info > #bloc_avis #avis {
    width: 100%!important;
    padding-top: 0;
  }
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content .description #vote {
    font-size: 10px;
  }
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content .description #bloc_prix_consulter #prix span {
    display: block;
    text-align: center;
    margin-bottom: 0.5rem;
    margin-right: 0;
  }
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content .description #bloc_prix_consulter #consulter span {
    display: block;
    text-align: center;
  }
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat .content p#nom_avocat {
    font-size: 1.2rem;
  }
}
@media all and (max-width: 1080px) {
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat {
    text-align: center;
    margin-bottom: 2rem;
  }
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat > .grid {
    display: inline-block;
  }
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat > .grid > #bloc_info {
    padding-left: 1rem;
    padding-top: 1rem;
    margin: auto;
    text-align: center;
  }
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat > .grid > #bloc_info > #bloc_avis #bloc_prix_consulter {
    display: grid;
  }
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat > .grid > #bloc_info > #bloc_avis #bloc_prix_consulter > #prix {
    margin-right: 0;
    margin-bottom: 10px;
  }
  #bloc_pubs > .grid .bloc_consultez_texte > #bloc_avocat > .grid > #profil_avocat {
    width: 60%!important;
  }
}
@media all and (max-width: 1080px) {
  #bloc_pubs .bloc_consultez_texte #bloc_avocat .content {
    text-align: center;
  }
}
/********************************[REDIGEZ UN MESSAGE : index]********************************/
#bloc_pubs > .grid #bloc_pub_redigez #bloc_image:hover > div {
  opacity: 0.8;
}
#bloc_pubs > .grid #bloc_pub_redigez #bloc_img_redigez {
  position: relative;
  border-bottom: 1px solid rgba(49, 146, 197, 0.5);
}
#bloc_pubs > .grid #bloc_pub_redigez #bloc_image a #bloc_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  width: 100%;
}
#bloc_pubs > .grid #bloc_pub_redigez #bloc_image a #bloc_text #sub_title {
  font-size: 12px;
  color: #3192C5;
  text-transform: uppercase;
  font-weight: bold;
}
#bloc_pubs > .grid #bloc_pub_redigez #bloc_image a #bloc_text #title {
  font-size: 18px;
  color: #3192C5;
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0px;
  margin-bottom: 10px;
  font-weight: 600!important;
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 1200px) {
  #bloc_pubs > .grid a #bloc_text #sub_title {
    margin-bottom: 0px;
  }
  #bloc_pubs > .grid a #bloc_text #title {
    margin-bottom: 0px;
    font-size: initial!important;
  }
}
@media all and (max-width: 1080px) {
  #bloc_pubs > .grid a #bloc_text #sub_title {
    margin-bottom: 10px;
  }
  #bloc_pubs > .grid a #bloc_text #title {
    margin-bottom: 10px;
  }
}
/********************************[PUB: forum]********************************/
#bloc_pubs > .grid #bloc_img_redigez {
  position: relative;
  border-bottom: 1px solid rgba(49, 146, 197, 0.5);
}
#bloc_pubs > .grid #bloc_img_redigez.rediger_msg {
  background-image: url(/ressources/images/rediger_msg.jpg);
  max-width: 100%;
  height: 210px;
}
#bloc_pubs > .grid #bloc_image:hover > div {
  opacity: 0.5;
}
#bloc_pubs > .grid a #bloc_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  width: 100%;
  text-align: center;
}
#bloc_pubs > .grid a #bloc_text #sub_title {
  font-size: 12px;
  color: #3192C5;
  text-transform: uppercase;
  font-weight: bold;
}
#bloc_pubs > .grid a #bloc_text #sub_title.white {
  color: #FFF;
}
#bloc_pubs > .grid a #bloc_text #title.white {
  color: #FFF;
}
#bloc_pubs.responsive {
  display: none;
}
/**Pub Large**/
.pub_large {
  width: 728px;
  height: 90px;
  background-color: black;
  margin-left: -1px;
}
/********************************[PUB RESPONSIVE: forum]********************************/
@media all and (max-width: 1200px) {
  #bloc_pubs .bottom_sidebar_avoloi > .content {
    font-size: 0.8rem;
  }
  #bloc_pubs .top_sidebar_avoloi .ui.header > .content .sub.header {
    font-size: 0.9rem;
  }
}
@media all and (max-width: 1080px) {
  #bloc_pubs {
    width: 100%!important;
  }
  #bloc_pubs.responsive {
    display: block;
    padding-right: 0;
  }
  #bloc_pubs.responsive > .grid > .column {
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }
  #bloc_pubs .container_sidebar_avoloi {
    display: none;
  }
  #bloc_pubs > .grid > .column {
    margin-bottom: 20px;
    display: none;
  }
  #bloc_pubs.home > .grid > .column {
    margin-bottom: 20px;
    display: block;
  }
  #bloc_pubs > .grid #bloc_img_redigez.rediger_msg {
    background-image: url(/ressources/images/rediger_msg_responsive.jpg);
  }
}
@media all and (max-width: 400px) {
  #bloc_pubs.responsive {
    padding-right: 1rem;
  }
  #bloc_pubs.responsive.liste_articles {
    padding-right: 0rem;
  }
  #bloc_pubs.responsive.liste_blogs {
    padding-right: 0rem;
  }
}
/**Section**/
/*********************************[ADMIN BLOG]*********************************/
/*Titre*/
#container_left > #container_title {
  margin-bottom: 1rem;
}
#container_left > #container_title > .column > h1 {
  font-size: 30px;
  font-weight: bold;
  color: #3192C5;
  text-transform: uppercase;
}
#container_left > #container_title > .column > p {
  font-size: 22px;
  font-weight: bold;
  color: #323232;
}
#container_left > #container_bloc {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 30px;
}
#container_left > #container_bloc > .column {
  outline: 1px solid #C3C8CD;
}
#container_left > #container_bloc > .column > .grid > #bloc_title {
  padding: 20px 40px;
  background: rgba(0, 0, 28, 0.05);
}
#container_left > #container_bloc > .column > .grid > #bloc_title > h3 {
  font-size: 22px;
  font-weight: bold;
  color: #122437;
  margin-bottom: 0;
}
#container_left > #container_bloc > .column > .grid > #bloc_title > p {
  font-size: 16px;
  font-weight: bold;
  color: rgba(18, 36, 55, 0.5);
}
#container_left > #container_bloc > .column > #container_choix {
  padding: 1rem 3rem;
}
#container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_img {
  display: block;
}
#container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_img i {
  font-size: 4em;
  display: block;
  text-align: center;
  color: #2e8ab9;
}
#container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_text > a {
  font-size: 20px;
  font-weight: bold;
  color: #3484B5;
}
#container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_text > a:hover {
  color: #16374A;
}
#container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_text > p {
  font-size: 15px;
  font-weight: 500;
  color: rgba(18, 36, 55, 0.5);
  margin-bottom: 0;
}
#container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_text > span > a {
  font-size: 16px;
  font-weight: bold;
  color: #3192C5;
}
#container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_text > span > a:hover {
  color: #16374A;
}
#container_left .file-added {
  display: block;
  background-color: #f2f2f2;
  padding: 10px;
  margin-bottom: 10px;
}
#container_left .file-added span {
  display: inline-block;
  margin: 10px 0 0 10px;
}
#container_left .file-added input {
  width: 200px;
}
/*Filgrane*/
.container_filigrane {
  font-size: 2em;
  color: rgba(29, 29, 29, 0.02);
  position: absolute;
  right: 0;
}
.container_filigrane i.circle {
  color: rgba(29, 29, 29, 0.01);
}
/*Message Info*/
#container_administration .message_info {
  box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
  background-color: #f8ffff;
  color: #276f86;
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  background: #f8f8f9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
}
#container_administration .message_info.nodescription {
  display: none!important;
}
#container_administration .count_characters {
  padding: 0.5em 0;
  background: none!important;
  color: #000!important;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 992px) {
  #container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_img {
    display: inline-table;
  }
  #container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_text {
    margin: auto;
    text-align: center;
  }
}
@media all and (max-width: 767px) {
  #container_left .etape-images .tabular.menu {
    display: block;
  }
  #container_left .etape-images .tabular.menu .active.item {
    border-bottom: 1px solid #D4D4D5;
  }
}
@media all and (max-width: 650px) {
  #container_left > #container_bloc > .column > #container_choix {
    padding: 1rem;
  }
  #container_left > #container_bloc > .column > #container_choix #blog_choix {
    width: 100%!important;
  }
  #container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_img {
    width: 100%!important;
  }
  #container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_img img {
    margin: auto;
  }
  #container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_text {
    width: 100%!important;
    text-align: center;
  }
  #container_left > #container_bloc > .column > #container_choix #blog_choix > .grid > #bloc_text > p {
    margin-top: 5px;
  }
  #container_left > #container_bloc > .column > .grid > #bloc_title {
    text-align: center;
  }
}
@media all and (max-width: 650px) {
  #container_left > #container_title > .column > h1 {
    font-size: 25px;
  }
  #container_left > #container_title > .column > p {
    font-size: 20px;
  }
}
/****************************[ADMINISTRATION/ARTICLE]**************************/
#container_administration {
  margin-top: 20px;
}
/**Blog LEFT Contenu**/
#container_left {
  /*Image de l'article*/
}
#container_left h2.header {
  background: #F2F2F2;
  letter-spacing: 0px;
}
#container_left .header .content {
  font-size: 18px;
}
#container_left .header .content .sub.header {
  font-size: 13px;
  font-weight: normal;
}
#container_left .segment table.table th.title {
  width: 95%;
}
#container_left .segment table.table th.delete {
  width: 5%;
}
#container_left .segment table.table .column_article th {
  width: 10%;
}
#container_left .segment table.table .column_article th.title {
  width: 80%;
}
#container_left .segment .accordion {
  width: 100%;
}
#container_left table tbody td > .new_msg {
  word-break: break-word;
}
#container_left .labeled#url_adresse_blog {
  margin-top: 1rem;
}
#container_left .menu_lateral_admin {
  display: inline-block;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  width: 80%;
}
#container_left .menu_lateral_admin#bloc_rss a {
  color: #FFF;
}
#container_left .menu_lateral_admin.bloc_rechercher {
  width: 60%;
}
#container_left #ordre_menu {
  margin: auto;
}
#container_left .etape-images .etape-images-container > .grid {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 0;
}
#container_left .etape-images .etape-images-container > .grid.pixabay_img {
  max-height: 500px;
  overflow-y: scroll;
  margin-right: 0;
}
/**Blog RIGHT Menu**/
#container_droite.menu_droite {
  padding-top: 0.8rem;
}
#container_droite .vertical.menu {
  width: 100%;
  margin-top: 0px;
  min-height: auto;
  outline: 1px solid rgba(34, 36, 38, 0.15);
  border: none;
  border-radius: 0;
}
#container_droite .vertical.menu a.item {
  font-size: 15px;
  display: flex;
}
#container_droite .vertical.menu a.item > .label > span {
  font-size: 10px;
}
#container_droite .vertical.menu .item > .label {
  margin: auto!important;
  margin-right: 0!important;
}
#container_droite #btn_menu_admin {
  display: none;
  position: relative;
}
/* Dropdown */
.label_menu {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}
#button_menu {
  display: none;
  padding: 15px 20px!important;
}
#button_menu:hover {
  background-color: #eeeeee;
}
#button_menu > i {
  margin: auto;
}
.dd-input {
  display: none;
}
.dd-input:checked + .vertical.menu {
  display: block;
}
#container_menu_right_responsive {
  display: none;
}
/**Gestion des articles**/
/*Classement de l'article*/
.theme_classement#modal-themes {
  max-width: 450px;
}
/**A propos**/
.apropos_infos_avatar#bloc_info > i.quote.icon {
  padding-right: 10px;
  float: left;
  font-size: 30px;
}
/**Statistiques**/
.tab_stats#visites {
  height: 400px;
}
.tab_stats_nb_visites td > em.url {
  font-size: 12px;
  color: #777;
  word-break: break-word;
}
/*******************************[RESPONSIVE]***********************************/
@media all and (max-width: 1200px) {
  /****BLOG LEFT****/
  #container_left.contenu_gauche {
    width: 68.75% !important;
  }
  #container_administration form .fields > .field#nb_position {
    width: 18%!important;
  }
  /****BLOC DROITE****/
  #container_droite .menu_droite {
    width: 31.25%!important;
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
  #container_droite .vertical.menu > a.item {
    font-size: 13px;
    display: inline-flex;
    width: 100%;
  }
  #container_droite .vertical.menu a.item {
    font-size: 13px;
  }
}
@media all and (max-width: 1080px) {
  #container_menu_right_responsive {
    display: block;
  }
  #container_administration form .fields > .field {
    margin-bottom: 15px;
  }
  #container_droite .vertical.menu a.item {
    font-size: 12px;
  }
}
@media all and (max-width: 1080px) {
  /****BLOC LEFT****/
  #container_administration form .fields > .field#nb_position {
    width: 100%!important;
  }
  #container_left h2.header > i {
    display: block;
    margin: auto;
  }
  #container_left .header .content {
    display: block;
    text-align: center;
  }
  #container_left #table_contenu_admin {
    width: 100%;
    text-align: center;
    margin: auto;
  }
  #container_left #nom_column_admin {
    display: none!important;
  }
  #container_left .labeled#url_adresse_blog {
    display: grid;
    font-size: 13px;
  }
  #container_left #ordre_menu {
    display: table-cell;
  }
  /****BLOC RIGHT****/
  #container_droite #btn_menu_admin {
    display: inline-block;
  }
  #container_droite #button_menu {
    display: inline-block;
    width: 100%!important;
  }
  #container_droite .vertical.menu a.item {
    display: grid;
    margin: auto;
    text-align: center;
    font-size: 11px;
  }
  #container_droite .vertical.menu a.item > i {
    margin: auto;
    margin-bottom: 5px;
  }
  #container_droite .vertical.menu .item > .label {
    margin-right: auto!important;
    margin-top: 5px!important;
  }
  #container_droite .label_menu .ui.vertical.menu .active.item {
    width: inherit;
  }
  #container_droite .message p {
    text-align: center;
  }
  #container_droite .message p > i {
    display: block;
    margin: auto;
    margin-bottom: 1rem;
  }
  .dd-input + .vertical.menu {
    display: none;
  }
}
@media all and (max-width: 1080px) {
  #container_administration #text_nb_visiteurs {
    width: 100%!important;
    text-align: left;
  }
  #container_administration #plage_date {
    width: 100%!important;
    text-align: left;
  }
}
@media all and (max-width: 1080px) {
  #container_left.administration h2.header > i {
    display: block;
  }
  #container_left.administration .header .content {
    width: 100%;
    display: block;
    padding-left: 0;
    line-height: 20px;
    margin-top: 5px;
  }
  #container_left.administration .header .content .sub.header {
    margin-top: 5px;
  }
  /****CONTAINER DROITE****/
  /**Menu droite responsive**/
  #container_menu_right_responsive .menu > a.item > .label {
    display: block;
    margin-left: 30px;
    margin-top: 5px;
  }
  #container_administration .ui.mobile.reversed {
    display: table;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }
  #container_administration .ui.mobile.reversed .menu_droite#container_droite {
    width: 100%!important;
    display: table-header-group;
  }
  #container_administration .ui.mobile.reversed .menu_droite#container_droite #button_menu {
    width: 100%!important;
  }
  #container_administration .ui.mobile.reversed .contenu_gauche#container_left {
    width: 100%!important;
    display: table-footer-group;
  }
}
@media all and (max-width: 1080px) {
  /****MENU DROITE RESPONSIVE****/
  #container_menu_right_responsive .menu > a.item {
    display: flex;
  }
  #container_left h2.header {
    margin-top: 1rem;
  }
  #container_left .header .content {
    padding-left: 0;
  }
  #container_administration .ui.mobile.reversed .contenu_gauche#container_left #container_diff_blocs_admin {
    width: 100%!important;
  }
  #container_administration #text_nb_visiteurs {
    width: 100%!important;
  }
  #container_droite .vertical.menu .container_menu_admin_verticle {
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
/*********************************[ARTICLE]************************************/
/*Admin*/
#container_left .admin_mettre_une select.ui.dropdown {
  height: 42px;
}
#container_left .admin_btns_article {
  display: inherit;
}
#container_left .admin_btns_article a.admin_article {
  padding: 15px 25px 15px 25px !important;
}
#container_left #bloc_title_article h1 {
  font-size: 33px;
  color: #66ABE7;
  margin-top: 0px;
  overflow-wrap: break-word;
}
#container_left #bloc_publie > .grid {
  font-size: 14px;
  color: rgba(25, 42, 69, 0.64);
}
#container_left #bloc_publie > .grid .left.floated .segment > span {
  margin-right: 1rem;
}
#container_left #reseaux_sociaux {
  margin-top: 1rem;
  padding-left: 1rem;
}
#container_left #reseaux_sociaux > #container_rs_left {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  vertical-align: top;
  background: #F7F8FA !important;
  text-align: left !important;
}
#container_left #reseaux_sociaux > #container_rs_left .bloc_rs {
  position: relative;
  top: 0;
  display: inline-block;
  margin: auto;
  background: #3192c5;
  margin-right: 2px;
  margin-left: -1px;
  height: 48px;
  width: 48px;
  text-align: center;
  transition-duration: 0.2s;
}
#container_left #reseaux_sociaux > #container_rs_left .bloc_rs:hover {
  transform: translateY(-4px);
  color: #fff;
  text-decoration: none;
}
#container_left #reseaux_sociaux > #container_rs_left .bloc_rs i {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  color: #FFF;
  font-size: 2em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: unset;
}
#container_left #reseaux_sociaux > #container_rs_left .bloc_rs i.fab {
  height: 29px;
  width: 24.5px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(136deg) brightness(101%) contrast(102%);
}
#container_left #reseaux_sociaux > #container_rs_left .bloc_rs.is-admin {
  background: red !important;
}
#container_left #reseaux_sociaux > #container_rs_left .bloc_rs.is-green {
  background: green !important;
}
#container_left #reseaux_sociaux > #container_rs_left .bloc_rs.is-grey {
  background: grey !important;
}
#container_left #reseaux_sociaux > #container_rs_left .container_rs_left-reseaux {
  display: inline-block;
  padding-right: 5px;
  background: #fff;
}
#container_left #reseaux_sociaux > #container_rs_left > .grid > .column {
  background: #2D4962;
  margin-right: 2px;
  height: 50px;
}
#container_left #reseaux_sociaux > #container_rs_left > .grid > .column:hover {
  background-color: #FFF;
  color: #3192C5;
  outline: 1px solid #3192C5;
}
#container_left #container_texte {
  margin-top: 2rem;
}
#container_left #container_texte #bloc_presentation > p {
  font-size: 18px;
  font-weight: 600;
  color: #323232;
  margin-bottom: 20px;
  text-align: justify;
  word-break: break-word;
}
#container_left #container_texte #bloc_img > img {
  width: 100%;
}
#container_left #container_texte #bloc_contenu {
  font-size: 15px;
  color: #000000;
  word-wrap: break-word;
  margin-bottom: 3rem;
  font-weight: 400;
  text-align: justify;
}
#container_left #container_texte #bloc_contenu h2 u,
#container_left #container_texte #bloc_contenu h3 u {
  text-decoration: none !important;
}
#container_left #container_texte #bloc_contenu h2 {
  font-size: 24px;
}
#container_left #container_texte #bloc_contenu h3 {
  font-size: 20px;
}
#container_left #container_texte #bloc_contenu h4 {
  font-size: 18px;
}
#container_left #container_texte #bloc_contenu h5 {
  font-size: 16px;
}
#container_left #container_texte #bloc_contenu h6 {
  font-size: 14px;
}
#container_left #container_texte #bloc_contenu .is-hidden {
  display: none;
}
#container_left #container_texte #bloc_contenu font {
  font-size: 15px;
}
#container_left #container_texte #bloc_contenu ul {
  list-style-type: disc;
  margin-left: 1rem;
}
#container_left #container_texte #bloc_contenu ol {
  list-style-type: decimal;
  margin-left: 1rem;
}
#container_left #container_texte #bloc_contenu > p > a {
  cursor: pointer;
}
#container_left #container_texte #bloc_contenu img {
  width: 100%;
  height: auto;
}
#container_left #container_texte #bloc_contenu blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  padding: .8rem;
  font-style: italic;
}
#container_left #container_texte table {
  width: 100% !important;
  margin-bottom: 1rem;
  white-space: nowrap;
  display: block !important;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
#container_left #container_texte tr > td > p > span {
  word-break: initial;
}
#container_left #bloc_texte #bloc_commentaire {
  font-size: 14px;
}
#container_left .article-files {
  margin-bottom: 15px;
}
#container_left .article-files .article-files-item {
  display: inline-block;
  margin-right: 10px;
}
#container_left #item_liste_articles {
  margin-top: 35px;
}
#container_left #item_liste_articles .content > .meta {
  margin-top: 0px;
}
#container_left #item_liste_articles .content a.header {
  color: #3192C5;
}
#container_left #item_liste_articles .content a.header:hover {
  color: #23527c;
}
/*******************************[RESPONSIVE]***********************************/
@media all and (max-width: 1080px) {
  /**Reseaux sociaux en dessous du titre**/
  #container_left #reseaux_sociaux > #container_rs_left {
    width: 100% !important;
    background: #fff !important;
  }
  #container_left #reseaux_sociaux > #container_rs_left .container_rs_left-reseaux {
    width: 100%;
    margin: auto;
    text-align: center;
  }
  #container_left #reseaux_sociaux > #container_rs_left .bloc_rs {
    width: 13%;
  }
  /**Responsive accordion**/
  #container_forum_pubs .contenu_articles {
    display: table-footer-group;
  }
}
@media all and (max-width: 767px) {
  #container_left .admin_mettre_une {
    width: 100% !important;
    text-align: center;
  }
  #container_left .admin_btns_article {
    width: 100% !important;
    text-align: center;
  }
  #container_left #bloc_presentation {
    width: 100%!important;
    padding-bottom: 0!important;
  }
  #container_left #bloc_img {
    width: 100%!important;
    padding-top: 0!important;
  }
  #container_left #bloc_img > img {
    width: 100%;
    max-height: 250px;
  }
  .bloc_rs.partage-mail {
    display: none !important;
  }
}
@media all and (max-width: 658px) {
  /*Administration*/
  #container_left .container_admin_article .column {
    width: 100% !important;
  }
  #container_left .container_admin_article .column .admin_article.button_blue,
  #container_left .container_admin_article .column .admin_article.button_wrong {
    width: 100% !important;
    margin: auto;
  }
  #container_left #bloc_title_article h1 {
    font-size: 2rem;
  }
  #container_left .admin_article#bloc_publie .column .segment {
    margin-bottom: 1rem;
  }
}
@media all and (max-width: 588px) {
  #container_left .admin_btns_article {
    display: inherit;
  }
}
@media all and (max-width: 410px) {
  #container_left #reseaux_sociaux > #container_rs_left .bloc_rs {
    width: 15%;
  }
}
@media all and (max-width: 400px) {
  #container_left #bloc_title_article h1 {
    font-size: 2rem;
  }
  #container_left #container_texte #bloc_presentation > p {
    font-size: 16px;
  }
  #container_left #container_texte #bloc_contenu {
    font-size: 14px;
  }
  #container_left #container_texte #bloc_contenu h2 {
    font-size: 20px;
    margin-top: 1rem;
  }
  #container_left .admin_btns_article a.button {
    width: 100% !important;
  }
}
@media all and (max-width: 370px) {
  #container_left .admin_btns_article {
    display: block;
  }
}
/***************************[ARTICLES RECENTS : blog/]*************************/
/*Titre*/
#container_left > #article_recent p {
  font-size: 18px;
  font-weight: bold;
  color: #152435;
  text-transform: uppercase;
  font-weight: 600!important;
}
#container_left h1.listing-title {
  font-size: 14px;
  color: #fff;
  padding: 1rem;
  background: #000;
  text-transform: uppercase;
  font-weight: 500 !important;
  margin-bottom: 20px;
  display: inline-block;
}
#container_left h1.listing-title .icon {
  margin-right: 10px;
}
#container_left .articles_recents #bloc_title {
  /*a {
				&:hover {
					text-decoration: none!important;
				}
			}*/
}
#container_left .articles_recents #bloc_title h1:hover {
  color: #23527c;
}
#container_left .articles_recents #bloc_title h2 {
  font-size: 22px;
  color: #66ABE7;
  margin-top: 0px;
  overflow-wrap: break-word;
}
#container_left .articles_recents #bloc_publie_le.articles_recents_blog .left.floated .segment span {
  margin-right: 25px;
}
#container_left .articles_recents #bloc_publie_le.articles_recents_blog span a {
  color: rgba(25, 42, 69, 0.64);
}
#container_left .articles_recents #bloc_publie_le > .grid {
  font-size: 14px;
  color: rgba(25, 42, 69, 0.64);
}
#container_left .articles_recents #bloc_forum_question #bloc_question {
  text-align: justify;
  overflow-wrap: break-word;
  font-size: 16px;
}
#container_left .articles_recents #bloc_question_article > .grid > #bloc_title {
  background: #2D4962;
}
#container_left .articles_recents #bloc_question_article > .grid > #bloc_title > span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
#container_left .articles_recents #bloc_question_article > #bloc_forum_question > #bloc_forum {
  margin-top: 20px;
  margin-bottom: 20px;
}
#container_left .articles_recents #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input {
  bottom: 0;
  position: absolute;
}
#container_left .articles_recents #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input > button {
  background-color: #FFF;
  border: 1px solid #3192C5;
  color: #2D4962;
  padding: 15px 50px;
  text-transform: uppercase;
  border-radius: 0px;
}
#container_left .articles_recents #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input > button:hover {
  background-color: #2D4962;
  color: #FFF;
}
#container_left .articles_recents #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input > button:hover + i {
  color: #FFF;
}
#container_left .articles_recents #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input > i {
  color: #2D4962;
  opacity: 1;
}
#container_left .articles_recents #bloc_question_article > #bloc_forum_question > #bloc_question {
  margin: auto;
}
#container_left .articles_recents #bloc_question_article > #bloc_forum_question > #bloc_question > a {
  color: #3192C5;
}
#container_left .articles_recents #bloc_question_article > #bloc_forum_question > #bloc_question > a:hover {
  color: #2D4962;
}
#container_left #divider.articles_recents_blog {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 658px) {
  #container_left .articles_recents #bloc_title h1 {
    font-size: 25px;
  }
  #container_left .articles_recents #bloc_publie_le > .grid > .column {
    display: inherit;
    text-align: left;
    padding-bottom: 0;
    padding-top: 5px;
  }
}
@media all and (max-width: 550px) {
  #container_left .articles_recents #bloc_publie_le > .grid > .column .segment {
    display: grid;
  }
  #container_left .articles_recents #bloc_forum_question #bloc_forum {
    width: 100%!important;
    margin: auto;
  }
  #container_left .articles_recents #bloc_forum_question #bloc_forum img {
    width: 100%!important;
  }
  #container_left .articles_recents #bloc_forum_question #bloc_question {
    width: 100%!important;
  }
}
/********************************[AVANTAGES]***********************************/
.container_avantages {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 50px;
  background: #f8f8f8;
}
.container_avantages .grid .column h2 {
  text-align: center;
  font-size: 30px;
  color: #070200;
}
.container_avantages .grid #container_contenu .list .item {
  border-top: none;
  margin-bottom: 20px;
}
.container_avantages .grid #container_contenu .list .item i {
  color: #3192C5;
}
.container_avantages .grid #container_contenu .list .item .content .header {
  font-size: 18px;
  color: #323232;
}
.container_avantages .grid #container_contenu .list .item .content .description {
  font-size: 14px;
  color: #919191;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 767px) {
  .container_avantages .grid #container_contenu > .grid {
    display: grid;
  }
  .container_avantages .grid #container_contenu > .grid > .column {
    width: 100%;
  }
}
/************************[BLOCS PRINCIPALS : ACCUEIL]**************************/
#container_blocs {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 1rem;
}
#container_blocs .grid {
  /**Blog Créer blog juridique + Posez question**/
}
#container_blocs .grid #container_bloc1 {
  width: 66.7%;
}
#container_blocs .grid #container_bloc1 > div {
  position: relative;
}
#container_blocs .grid #container_bloc1 img.image {
  width: 725px;
  height: 370px;
}
#container_blocs .grid #container_bloc1 .bloc_hastag {
  position: absolute;
  bottom: 0px;
  background: #FFF;
  padding: 20px;
  padding-left: 0;
  width: 500px;
  height: auto;
}
#container_blocs .grid #container_bloc1 .bloc_hastag > div > a {
  color: #3192C5;
  font-size: 21px;
  text-transform: uppercase;
  padding-top: 10px;
  font-weight: 600;
  line-height: normal;
  word-break: break-word;
  padding-right: 2rem;
  display: block;
  padding-top: 0;
  padding-bottom: 0;
}
#container_blocs .grid #container_bloc1 .bloc_hastag > div > a:hover {
  color: #23527c;
  text-decoration: underline;
}
#container_blocs .grid #container_bloc1 .bloc_hastag > div > .grid {
  padding-top: 10px;
}
#container_blocs .grid #container_bloc1 .bloc_hastag > div > .grid > #bloc_date_auteur {
  padding-bottom: 0px;
  font-size: 14px;
  color: rgba(25, 42, 69, 0.4);
}
#container_blocs .grid #container_bloc1 .bloc_hastag > div > .grid > #bloc_date_auteur a {
  color: rgba(25, 42, 69, 0.4);
}
#container_blocs .grid #container_bloc1 .bloc_hastag > div > .grid > #bloc_date_auteur a:hover {
  color: #23527c;
  text-decoration: underline;
}
#container_blocs .grid #container_bloc1 .bloc_hastag > div > .grid > #bloc_comment {
  padding-bottom: 0px;
  font-size: 14px;
  color: rgba(25, 42, 69, 0.4);
}
#container_blocs .grid #container_bloc1 .bloc_hastag > div > .grid > #bloc_droit {
  padding-top: 0px;
  font-size: 14px;
}
#container_blocs .grid #container_bloc1 .bloc_hastag > div > .grid > #bloc_droit a {
  font-size: 14px;
  color: rgba(26, 42, 69, 0.4);
  text-transform: capitalize;
}
#container_blocs .grid #container_bloc1 .bloc_hastag > div > .grid > #bloc_droit a:hover {
  color: #23527c;
  text-decoration: underline;
}
#container_blocs .grid #container_bloc2_bloc3 {
  width: 33.3%;
}
#container_blocs .grid #container_bloc2_bloc3 #bloc_creer_votre_blog {
  width: 100%!important;
}
#container_blocs .grid #container_bloc2_bloc3 #bloc_creer_votre_blog .ui.grid > .column:not(.row) {
  width: 100%!important;
}
#container_blocs .grid #container_bloc2_bloc3 #bloc_creer_votre_blog .ui.grid > .row > .column {
  width: 100%!important;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > div {
  position: relative;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > div.creer_blog {
  background-image: url(/ressources/images/home/creerblog.jpg);
  max-width: 100%;
  height: 210px;
  background-size: cover;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > div.forum_juridique {
  background-image: url(/ressources/images/home/forumjuridique.jpg);
  max-width: 100%;
  height: 132px;
  background-size: cover;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image.posez_question:hover > div {
  opacity: 0.8;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image:hover > div {
  opacity: 0.5;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > a > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  width: 100%;
  padding: 30px;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > a > div > #sub_title {
  font-size: 12px;
  color: #3192C5;
  text-transform: uppercase;
  font-weight: bold;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > a > div > #title {
  font-size: 20px;
  color: #3192C5;
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0px;
  margin-bottom: 10px;
  font-weight: 600!important;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > a > div > #sous_paragh {
  font-size: 14px;
  color: #3192C5;
  padding-top: 1rem;
  text-align: justify;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > a > div > #sub_title_fj {
  font-size: 12px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: bold;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > a > div > #title_fj {
  font-size: 20px;
  color: #FFF;
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0px;
  margin-bottom: 10px;
  font-weight: 600!important;
}
#container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > a > div > #sous_paragh_fj {
  font-size: 13px;
  color: #F9980C;
  padding-top: 5px;
  text-align: center;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1200px) {
  #container_blocs .grid #container_bloc1 {
    width: 100%!important;
  }
  #container_blocs .grid #container_bloc1 img.image {
    width: 100%;
    height: auto;
  }
  #container_blocs .grid #container_bloc2_bloc3 {
    width: 100%;
  }
  #container_blocs .grid #container_bloc2_bloc3 #bloc_creer_votre_blog {
    width: 50%;
  }
  #container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > div.forum_juridique {
    height: 210px;
  }
  #container_blocs .grid #container_bloc2_bloc3 > .grid > .column > #bloc_image > a > div > #sous_paragh {
    text-align: center;
  }
}
@media all and (max-width: 768px) {
  #container_blocs .grid #container_bloc1 .bloc_hastag {
    position: inherit!important;
    width: auto!important;
  }
  #container_blocs .grid #container_bloc1 .bloc_hastag > div > a {
    font-size: 18px;
  }
  #container_blocs .grid #container_bloc2_bloc3 #bloc_creer_votre_blog {
    width: 100%;
  }
  #container_blocs .grid #container_bloc2_bloc3 #bloc_creer_votre_blog > .grid > .column > #bloc_image > a > div > #title {
    font-size: 14px;
  }
  #container_blocs .grid #container_bloc2_bloc3 #bloc_creer_votre_blog > .grid > .column > #bloc_image > a > div > #title_fj {
    font-size: 14px;
  }
}
@media all and (max-width: 400px) {
  #container_blocs {
    padding: 0;
  }
  #container_blocs .grid #container_bloc2_bloc3 {
    display: none;
  }
}
/***************************[BLOCS SECONDAIRES : ACCUEIL]**********************/
#container_bloc4 {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 40px;
}
#container_bloc4 > .grid > #colonnes_bloc {
  margin-bottom: 80px;
}
#container_bloc4 > .grid > #colonnes_bloc > div {
  position: relative;
  max-height: 172px;
  overflow-y: hidden;
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_title {
  background: #FFF;
  left: 0px;
  bottom: 0px;
  position: absolute;
  padding: 15px;
  width: 83%;
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_title > a {
  color: #3192C5;
  font-size: 17px;
  font-weight: bold;
  word-break: break-word;
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_title > a:hover {
  color: #23527c;
  text-decoration: underline;
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_date_auteur {
  position: absolute;
  padding: 15px;
  padding-left: 0px;
  display: grid;
  width: 95%;
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_date_auteur #bloc_date_comment {
  font-size: 14px;
  color: rgba(26, 42, 69, 0.4);
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_date_auteur #bloc_date_comment > #bloc_comment {
  float: right;
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_date_auteur #bloc_auteur {
  font-size: 14px;
  color: rgba(26, 42, 69, 0.4);
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_date_auteur #bloc_auteur > a {
  color: rgba(25, 42, 69, 0.4);
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_date_auteur #bloc_auteur > a:hover {
  color: #23527c;
  text-decoration: underline;
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_date_auteur #bloc_droit a {
  font-size: 14px;
  color: rgba(26, 42, 69, 0.4);
  text-transform: capitalize;
}
#container_bloc4 > .grid > #colonnes_bloc > #bloc_date_auteur #bloc_droit a:hover {
  color: #23527c;
  text-decoration: underline;
}
/*******************************[RESPONSIVE]***********************************/
@media all and (max-width: 1080px) {
  #container_bloc4 > .grid > #colonnes_bloc > #bloc_title > a {
    font-size: 13px;
  }
}
@media all and (max-width: 768px) {
  #container_bloc4 {
    margin-bottom: 30px!important;
  }
  #container_bloc4 > .grid > #colonnes_bloc > #bloc_title > a {
    font-size: larger;
  }
  #colonnes_bloc {
    width: 100%;
    margin-bottom: 80px;
  }
}
@media all and (max-width: 400px) {
  #container_bloc4 {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
}
/**********************************[BLOG]**************************************/
#container_left #article_recent {
  font-size: 26px;
  font-weight: 600!important;
  margin-bottom: 2rem;
}
/*****************************[DONNEZ DE LA VISIBILITE]************************/
.container_visibilite {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f8f8f8;
}
.container_visibilite .grid h2 {
  text-align: center;
  font-size: 30px;
  color: #9b98ac;
}
.container_visibilite .grid #bloc_compteur .grid .column .segment {
  text-align: center;
  background: #33363a;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
.container_visibilite .grid #bloc_compteur .grid .column .segment .counter {
  color: #FFF;
  font-size: 40px;
  font-weight: 600!important;
  line-height: 36px;
}
.container_visibilite .grid #bloc_compteur .grid .column .segment .counterBoxDetails {
  color: #FFF;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: normal;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1200px) {
  .container_visibilite .grid h2 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media all and (max-width: 1080px) {
  .container_visibilite .grid #bloc_compteur .grid .column .segment .counter {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .container_visibilite .grid #bloc_compteur .grid {
    width: 100%;
    margin: auto;
  }
  .container_visibilite .grid #bloc_compteur .grid .column {
    width: 100%;
  }
}
/********************************[CONTACT]*************************************/
.map_contact {
  position: relative;
  width: 100%;
  height: 500px;
  z-index: 0;
}
.clauses_form_contact {
  padding-right: 20px;
  text-align: justify;
  margin-bottom: 20px;
  font-size: 10px;
  max-height: 100px;
  overflow-y: scroll;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 400px) {
  #container_droite.contact {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
/*****************************[CREER VOTRE BLOG JURIDIQUE]*********************/
/**BASES**/
#container_creer_blog {
  padding: 3rem;
  background: #f8f8f8;
  /*Container white*/
  /*Container grey*/
  /*Container black*/
}
#container_creer_blog.container_bloc_white {
  background: #ffffff;
}
#container_creer_blog.container_bloc_white h2 {
  color: #9b98ac!important;
}
#container_creer_blog.container_bloc_white .border_left {
  box-shadow: -1px 0 0 0 rgba(51, 54, 58, 0.15);
}
#container_creer_blog.container_bloc_white .border_bottom {
  box-shadow: 0 1px 1px -1px rgba(51, 54, 58, 0.15);
}
#container_creer_blog.container_bloc_white p {
  font-size: 16px;
  font-weight: 300;
}
#container_creer_blog.container_bloc_grey {
  background: #f8f8f8;
}
#container_creer_blog.container_bloc_grey h2 {
  color: #9b98ac!important;
}
#container_creer_blog.container_bloc_grey .border_left {
  box-shadow: -1px 0 0 0 rgba(51, 54, 58, 0.15);
}
#container_creer_blog.container_bloc_grey .border_bottom {
  box-shadow: 0 1px 1px -1px rgba(51, 54, 58, 0.15);
}
#container_creer_blog.container_bloc_dark {
  background: #33363a;
}
#container_creer_blog.container_bloc_dark h2 {
  color: #FFF!important;
  font-size: 30px;
}
#container_creer_blog.container_bloc_dark h3 {
  color: #FFF!important;
}
#container_creer_blog.container_bloc_dark p {
  color: #FFF!important;
  font-weight: 300;
}
#container_creer_blog.container_bloc_dark b {
  color: #3192C5 !important;
}
#container_creer_blog.container_bloc_dark .border_left {
  box-shadow: -1px 0 0 0 rgba(212, 212, 213, 0.15);
}
#container_creer_blog.container_bloc_dark .border_bottom {
  box-shadow: 0 1px 1px -1px rgba(212, 212, 213, 0.15);
}
#container_creer_blog.container_bloc_dark i {
  color: #d4d4d5;
}
#container_creer_blog.container_bloc_dark i.circular.icon {
  box-shadow: 0 0 0 0.1em rgba(212, 212, 213, 0.15) inset;
}
#container_creer_blog img.img_nhesitez_pas {
  width: 50%!important;
}
#container_creer_blog button i.fab {
  height: 20px;
  width: 20px;
  top: 9px;
  right: 10px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(136deg) brightness(101%) contrast(102%);
}
#container_creer_blog button:hover i.fab {
  filter: invert(53%) sepia(47%) saturate(598%) hue-rotate(156deg) brightness(86%) contrast(102%);
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 767px) {
  #container_creer_blog.container_bloc_white .border_bottom {
    box-shadow: none;
  }
  #container_creer_blog.container_bloc_dark .border_bottom {
    box-shadow: none;
  }
  #container_creer_blog .segment .ui.grid .ui.stackable.grid {
    margin: auto!important;
  }
  #container_creer_blog .segment .ui.stackable.divided:not(.vertically).grid > .row > .column {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
  #container_creer_blog img.img_nhesitez_pas {
    width: 30%!important;
  }
  #container_creer_blog img.img_all_plateformes {
    width: 70%!important;
    margin: auto;
  }
}
@media all and (max-width: 400px) {
  #container_creer_blog {
    padding: 0;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  #container_creer_blog.container_bloc_dark .ui.stackable.grid {
    margin-left: 1rem!important;
    margin-right: 1rem!important;
  }
  #container_creer_blog.container_avantages {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
/*****************************[DERNIERES PUBLICATIONS]*************************/
#container_dern_public {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
  padding-top: 40px;
  background: #F7F8FA;
  margin: auto;
  height: auto;
}
#container_dern_public > .grid > #bloc_title h2 {
  font-size: 25px;
  color: #192A45;
}
#container_dern_public > .grid > #bloc_title h2 i {
  color: #192A45;
  font-size: 2.5rem;
}
#container_dern_public > .grid #bloc_text_publications #bloc_date {
  color: rgba(26, 42, 69, 0.4);
  padding-top: 10px;
}
#container_dern_public > .grid #bloc_text_publications #bloc_text {
  font-size: 15px;
  color: #3192C5;
  padding-bottom: 10px;
  padding-top: 10px;
  display: grid;
}
#container_dern_public > .grid #bloc_text_publications #bloc_text:hover a {
  color: #23527c;
  text-decoration: underline;
}
#container_dern_public > .grid #bloc_text_publications #bloc_text a {
  word-break: break-word;
}
#container_dern_public > .grid #bloc_text_publications #bloc_publications {
  font-size: 12px;
  font-weight: 600!important;
  color: #3192C5;
  padding-top: 20px;
  text-transform: uppercase;
}
#container_dern_public > .grid #bloc_text_publications #bloc_publications a:hover {
  color: #23527c;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1080px) {
  #bloc_text_publications {
    display: block;
  }
  #bloc_text_publications .column {
    width: 100%!important;
  }
  #container_dern_public > .grid #bloc_text_publications #bloc_date {
    padding-bottom: 0;
  }
  #container_dern_public > .grid #bloc_text_publications #bloc_publications {
    text-align: left;
    float: none;
    width: 100%!important;
    padding-bottom: 0;
  }
}
@media all and (max-width: 650px) {
  #container_dern_public > .ui.grid > [class*="one wide"].column {
    width: 100%!important;
    padding-bottom: 0;
  }
  #container_dern_public > .ui.grid > #bloc_title {
    width: 100%!important;
  }
}
@media all and (max-width: 400px) {
  #container_dern_public {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
}
/********************************[DERNIERS SUJETS]********************************/
#container_dern_sujets {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 50px;
  padding-top: 40px;
  padding-bottom: 100px;
}
#container_dern_sujets > .grid > #bloc_title h2 {
  font-size: 25px;
  color: #192A45;
}
#container_dern_sujets > .grid > #bloc_title h2 i {
  color: #192A45;
  font-size: 2.5rem;
}
#container_dern_sujets > .grid #bloc_derniers_sujets #bloc_icone > a > i {
  color: #FA980D;
}
#container_dern_sujets > .grid #bloc_derniers_sujets #bloc_text {
  font-size: 15px;
  color: #3192C5;
}
#container_dern_sujets > .grid #bloc_derniers_sujets #bloc_text a {
  word-break: break-word;
}
#container_dern_sujets > .grid #bloc_derniers_sujets #bloc_text:hover a {
  color: #23527c;
  text-decoration: underline;
}
#container_dern_sujets > .grid #bloc_derniers_sujets #bloc_comment {
  font-size: 14px;
  color: rgba(25, 42, 69, 0.4);
  text-align: right;
}
#container_dern_sujets > .grid #bloc_derniers_sujets #bloc_comment i {
  margin-left: 10px;
}
#container_dern_sujets > .grid #bloc_derniers_sujets #divider {
  border-bottom: 1px dotted rgba(180, 186, 194, 0.57);
  padding: 0;
  margin-left: 15px;
  margin-right: 20px;
}
#container_dern_sujets > .grid #bloc_derniers_sujets #bloc_question {
  font-size: 12px;
  font-weight: 600!important;
  color: #3192C5;
  padding-top: 20px;
  text-transform: uppercase;
}
#container_dern_sujets > .grid #bloc_derniers_sujets #bloc_question a:hover {
  color: #23527c;
}
/********************************[RESPONSIVE]********************************/
@media all and (max-width: 1080px) {
  #bloc_derniers_sujets {
    width: 100%!important;
    margin-bottom: 20px;
  }
  #container_dern_sujets > .grid #bloc_derniers_sujets #bloc_question {
    float: left;
    width: 100%!important;
    padding-bottom: 0;
    text-align: left;
  }
}
@media all and (max-width: 700px) {
  #container_dern_sujets > .ui.grid > [class*="one wide"].column {
    width: 100%!important;
    padding-bottom: 0;
  }
  #container_dern_sujets > .ui.grid > #bloc_title {
    width: 100%!important;
  }
  #container_dern_sujets > .grid #bloc_derniers_sujets #bloc_question {
    float: none;
    width: 100%!important;
    padding-bottom: 0;
  }
}
@media all and (max-width: 600px) {
  #container_dern_sujets > .grid #bloc_derniers_sujets #bloc_comment {
    display: none;
  }
}
@media all and (max-width: 400px) {
  #container_dern_sujets {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
}
/********************************[ERROR]********************************/
.container_error {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  flex-direction: column;
  margin-bottom: 30px;
}
.container_error .body_error {
  display: flex;
  align-items: center;
  margin: 10px;
}
.container_error .bloc_error {
  width: 300px;
  height: 200px;
  border: 3px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
}
.container_error .error {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 80px;
  margin-left: 80px;
}
.container_error .texte {
  font-size: 60px;
}
/*******************************[ESPACE MEMBRE]********************************/
/**Mot de passe oublié**/
.container_mdp_forget {
  width: 100%!important;
}
/**Devenir Membre**/
.container_devenir_membre {
  width: 100%!important;
}
/**Menu Left**/
#bloc_menu_left > .grid .menu > a.delete_profil {
  color: red;
}
#bloc_contenu_right > .grid table > thead th.accept {
  width: 20px;
}
#bloc_contenu_right > .grid table > tbody td .button i.fa-check,
#bloc_contenu_right > .grid table > tbody td .button i.fa-eye {
  color: green;
}
#bloc_contenu_right form .fields .twelve.field {
  padding: 0;
}
#bloc_contenu_right form .fields .twelve.field > .field {
  width: 100%;
  padding: 0;
}
#bloc_contenu_right form .fields .twelve.field > .field > select {
  width: 100%;
}
#bloc_contenu_right #upload_photo {
  margin-top: 30px;
  width: 100%;
}
#bloc_contenu_right .avatar .bloc_img_profil {
  text-align: center;
}
#bloc_contenu_right .avatar img {
  margin-bottom: 2rem;
}
#bloc_contenu_right .list {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
#bloc_contenu_right .list .item {
  padding: 10px 0px;
}
/************************[FIL D'ARIANE : forum/message]************************/
#container_ariane {
  background-image: url(/ressources/images/background_defaut_header_presentation.png);
  width: 100%;
  background-repeat: repeat;
}
#container_ariane.blog {
  background-image: none;
}
#container_ariane.blog > .grid > #bloc_ariane {
  padding-bottom: 0;
}
#container_ariane > .grid > #bloc_ariane {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  padding: 20px;
  margin: 0px;
  padding-left: 50px;
}
#container_ariane > .grid > #bloc_ariane.blog {
  padding-left: 1rem;
}
#container_ariane > .grid > #bloc_ariane.blog a {
  color: #3192C5;
  text-transform: uppercase;
}
#container_ariane > .grid > #bloc_ariane.blog a:hover {
  color: #23527c;
}
#container_ariane > .grid > #bloc_ariane a {
  color: rgba(255, 255, 255, 0.4);
}
#container_ariane > .grid > #bloc_ariane a:hover {
  text-decoration: underline;
}
#container_ariane > .grid > #bloc_ariane i {
  color: rgba(255, 255, 255, 0.4);
  margin: 0 0.40rem;
}
.breadcrumb {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  padding: 10px 0 20px 0;
  margin: 0px;
}
.breadcrumb a {
  color: #3192C5;
  text-transform: initial;
}
.breadcrumb a:hover {
  color: #23527c;
}
/********************************[RESPONSIVE***********************************/
@media all and (max-width: 1080px) {
  #container_ariane {
    margin-top: 10px;
    margin-left: 0!important;
    margin-right: 0!important;
    margin-bottom: 10px;
  }
  #container_ariane > .grid {
    margin: auto;
    margin-bottom: -1rem;
  }
}
@media all and (max-width: 990px) {
  #container_ariane > .grid > #bloc_ariane.blog {
    padding-left: 0;
  }
}
@media all and (max-width: 658px) {
  #container_ariane > .grid > #bloc_ariane {
    padding: 20px;
    width: 100%;
  }
}
@media all and (max-width: 400px) {
  #container_ariane.blog > .grid > #bloc_ariane {
    padding: 20px!important;
  }
  #container_ariane > .grid > #bloc_ariane {
    padding: 10px 0!important;
  }
}
/**************************[FORMULAIRE : Page article]*************************/
#container_contenu_global #container_left #bloc_publie_par.form_new_question {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#container_contenu_global #container_repondre {
  margin-top: 70px;
  margin: auto;
  margin-bottom: 20px;
}
#container_contenu_global #container_repondre .is-hidden {
  display: none;
}
#container_contenu_global #container_repondre > #bloc_repondre {
  background: #F7F8FA;
  padding-bottom: 40px;
  margin: auto;
}
#container_contenu_global #container_repondre > #bloc_repondre > #bloc_title {
  background-repeat: repeat;
  height: auto;
  background: #3192C5;
  margin-bottom: 15px;
}
#container_contenu_global #container_repondre > #bloc_repondre > #bloc_title > span .content {
  color: #FFF;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600!important;
  text-align: left;
  display: inherit;
  float: left;
  line-height: 1.2em;
}
#container_contenu_global #container_repondre > #bloc_repondre > #bloc_title > span i {
  position: relative;
  top: -4px;
  font-size: 1em;
  color: #FFF;
}
#container_contenu_global #container_repondre > #bloc_repondre > #zone-msg {
  margin-left: 20px;
  margin-right: 20px;
}
#container_contenu_global #container_repondre > #bloc_repondre > #bloc_textarea > .form textarea {
  border-radius: 0;
  resize: none;
}
#container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express {
  color: #132433;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 20px;
}
#container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express.disclaimers {
  text-transform: inherit;
}
#container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express .container_insc_express-warning {
  margin: 15px 0 30px 0;
  padding: 15px;
  background: #3192c5!important;
  color: #fff;
  text-transform: none;
}
#container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express .container_insc_express-warning a {
  color: #fff;
  text-decoration: underline;
}
#container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express .count_characters {
  padding: .5em 0;
  background: 0 0!important;
  color: #000!important;
}
#container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express .clauses_form_article {
  font-size: 10px;
  text-align: justify;
  max-height: 100px;
  overflow-y: scroll;
}
#container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express #bloc_title {
  margin-bottom: 20px;
}
#container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express > input {
  border-radius: 0;
}
#container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express.container_btn_valid {
  width: 100%;
}
#container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express.container_btn_valid > #bloc_btn_pub {
  float: right;
}
#container_contenu_global #container_repondre > #bloc_repondre .label_required_name {
  color: #132433;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}
/*******************************[FORMULAIRE : MENU LIBRE]**********************/
/**Edit in place**/
.editinplace-btn {
  min-height: 50px;
  border: 2px dashed rgba(0, 0, 0, 0.4);
}
.editinplace-btn.is-noborder {
  border: none !important;
}
.editinplace-btn:hover {
  opacity: 0.4;
  cursor: pointer;
  background: #fff;
  border: 2px dashed #3192C5;
}
.editinplace-target {
  display: none;
}
.editinplace-target .ui.input {
  width: 100%;
  margin-bottom: 5px;
}
/**Sortable**/
.sortable-placeholder {
  min-height: 20px;
  background-color: #eee;
  border: 1px solid #eee;
}
/*******************************[RESPONSIVE]***********************************/
@media all and (max-width: 1080px) {
  .form_repondre_forum_responsive {
    width: 100%;
  }
}
@media all and (max-width: 500px) {
  #container_contenu_global #container_repondre > #bloc_repondre > #bloc_title > span .content {
    font-size: 16px;
  }
}
@media all and (max-width: 400px) {
  #container_contenu_global #container_repondre > #bloc_repondre > #bloc_title > span .content {
    font-size: small;
  }
  #container_contenu_global #container_left #bloc_publie_par.form_new_question {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
/********************************[FORUM]***************************************/
#bloc_title_orange {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
#bloc_title_orange span {
  background-color: #FF880F;
  color: #FFF;
  font-size: 14px;
  padding: 10px 20px;
}
#bloc_liste_forum #bloc_icone > a > i {
  color: #FF880F;
}
#bloc_liste_forum #bloc_text {
  font-size: 16px;
  color: #3192C5;
}
#bloc_liste_forum #bloc_text a:hover {
  color: #23527c;
  text-decoration: underline;
}
#bloc_liste_forum #bloc_text .breadcrumb {
  display: block;
  padding-bottom: 0;
  padding-top: 0.2rem;
}
#bloc_liste_forum #bloc_text .breadcrumb > ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  margin-bottom: 0;
}
#bloc_liste_forum #bloc_text .breadcrumb a,
#bloc_liste_forum #bloc_text .breadcrumb span {
  color: rgba(25, 42, 69, 0.4);
  font-size: 12px;
  margin-top: 0.4rem;
  text-transform: initial;
}
#bloc_liste_forum #bloc_text p > a {
  color: rgba(25, 42, 69, 0.4);
}
#bloc_liste_forum #bloc_text #bloc_comment_responsive {
  font-size: 14px;
  color: rgba(25, 42, 69, 0.4);
  display: none;
}
#bloc_liste_forum #bloc_text #bloc_date_responsive {
  font-size: 14px;
  color: rgba(25, 42, 69, 0.4);
  display: none;
}
#bloc_liste_forum #bloc_comment {
  font-size: 14px;
  color: rgba(25, 42, 69, 0.4);
  text-align: right;
}
#bloc_liste_forum #bloc_comment span > i {
  margin-left: 0.5rem;
}
#bloc_liste_forum #bloc_date {
  font-size: 14px;
  color: rgba(25, 42, 69, 0.4);
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1200px) {
  /****AUJOURD'HUI****/
  #bloc_liste_forum #bloc_text .breadcrumb > ul {
    margin-bottom: 1em;
    display: block;
  }
  #bloc_liste_forum #bloc_text .breadcrumb > ul > li > i {
    display: none;
  }
}
@media all and (max-width: 1080px) {
  /****PUBS****/
  #container_forum_pubs {
    display: table;
    padding-left: 20px;
    padding-right: 20px;
  }
  #container_forum_pubs.accueil_forum {
    padding-left: 0px;
    padding-right: 0px;
    /*.container_accordion_thematiques {
				display: none;
			}*/
  }
  #container_forum_pubs.accueil_forum .container_thematiques_forum_down .container_accordion_thematiques {
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }
  /****AUJOURD'HUI****/
  #bloc_liste_forum {
    width: 100%!important;
    display: table-footer-group;
  }
  #bloc_liste_forum > .grid {
    margin-right: 1rem!important;
  }
}
@media all and (max-width: 768px) {
  /****AUJOURD'HUI****/
  #bloc_liste_forum #bloc_icone {
    margin-left: 0;
  }
  #bloc_liste_forum #bloc_text {
    width: 90%!important;
  }
  #bloc_liste_forum #bloc_text #bloc_comment_responsive,
  #bloc_liste_forum #bloc_text #bloc_date_responsive {
    display: inline;
  }
  #bloc_liste_forum #bloc_date,
  #bloc_comment {
    display: none;
  }
  #container_forum_pubs {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media all and (max-width: 400px) {
  /****PUBS****/
  #container_forum_pubs {
    padding-left: 0px;
    padding-right: 0px;
    display: block;
  }
  #container_forum_pubs .container_sujet {
    display: block!important;
  }
  #bloc_title_orange {
    margin-left: 1rem;
  }
  #bloc_liste_forum > .grid {
    margin: auto!important;
  }
  #bloc_liste_forum #bloc_icone {
    margin-top: 0;
    display: block;
    text-align: center;
    width: 100%!important;
  }
}
/***********[HEADER PRESENTATION ex : créer votre blog juridique]**************/
#container_header_presentation {
  background-image: url(/ressources/images/background_defaut_header_presentation.png);
  height: auto;
}
#container_header_presentation > .grid {
  padding: 5px;
}
#container_header_presentation > .grid#grid_creer_blog {
  background-image: url(/ressources/images/blog/creer_blog_juridique/background_website_pc.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}
#container_header_presentation > .grid > #bloc_header_presentation {
  display: table;
  margin: auto 0;
}
#container_header_presentation > .grid > #bloc_header_presentation > .grid {
  vertical-align: middle;
  display: table-cell;
  padding: 10px 15px;
}
#container_header_presentation > .grid > #bloc_header_presentation > .grid h1 {
  color: #3192C5;
  font-size: 33px;
  word-break: normal;
}
#container_header_presentation > .grid > #bloc_header_presentation > .grid p {
  color: #AFB5BB;
  font-size: 20px;
  font-weight: 500;
  padding-right: 100px;
  text-align: justify;
}
#container_header_presentation #bloc_header_icone {
  margin: auto;
}
#container_header_presentation #bloc_header_icone > .grid {
  margin: auto;
}
#container_header_presentation #bloc_header_icone > .grid i {
  color: #FFF;
  font-size: 12em;
  margin: auto;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1200px) {
  #grid_creer_blog {
    background-size: cover!important;
    background-position: inherit!important;
  }
  #grid_creer_blog #bloc_header_presentation > .grid p {
    padding-right: 0!important;
  }
}
@media all and (max-width: 1080px) {
  #container_header_presentation {
    width: 100%!important;
    background-position: center;
    display: grid;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  #container_header_presentation > .grid#grid_creer_blog {
    background-image: none;
  }
  #container_header_presentation > .grid#grid_creer_blog #bloc_header_presentation {
    width: 100%!important;
  }
  #container_header_presentation > .grid > #bloc_header_presentation > .grid p {
    padding-right: 100px;
  }
}
@media all and (max-width: 992px) {
  #container_header_presentation {
    background-position: initial;
    margin-left: 0!important;
  }
  #container_header_presentation > .grid > #bloc_header_presentation {
    width: 100%!important;
  }
  #container_header_presentation > .grid > #bloc_header_presentation > .grid p {
    padding-right: 0;
  }
  #container_header_presentation #bloc_header_icone {
    display: none;
  }
}
@media all and (max-width: 767px) {
  #container_header_presentation > .grid > #bloc_header_presentation > .grid p {
    display: none;
  }
}
@media all and (max-width: 400px) {
  #container_header_presentation > .grid > #bloc_header_presentation > .grid p {
    text-align: left;
  }
}
@media all and (max-width: 400px) {
  #container_header_presentation > .grid > #bloc_header_presentation > .grid {
    padding: 1rem;
  }
  #container_header_presentation > .grid > #bloc_header_presentation > .grid h1 {
    font-size: 27px;
  }
  #container_header_presentation > .grid#grid_creer_blog #bloc_header_presentation {
    padding-left: 0;
    padding-right: 0;
  }
  #container_header_presentation > .grid#grid_creer_blog #bloc_header_presentation button {
    width: 100%!important;
  }
}
/********************************[LISTE BLOGS]***************************************/
#container_liste_blogs {
  margin-top: 35px;
}
#container_liste_blogs .content {
  padding-top: 10px;
}
#container_liste_blogs .content a.header {
  color: #3192C5;
}
#container_liste_blogs .content a.header:hover {
  color: #23527c;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 767px) {
  #container_liste_blogs .content {
    padding-top: 20px;
  }
}
/********************************[MESSAGE FORUM]*******************************/
#container_contenu_global.message_contenu.publications {
  padding-left: 1rem;
  padding-right: 1rem;
}
#container_contenu_global #commentaires {
  padding: 2rem 0;
}
#container_contenu_global #commentaires .bloc_msg {
  margin-bottom: 15px;
}
#container_contenu_global .bloc_msg > #bloc_publie_par,
#container_contenu_global #bloc_msg > #bloc_publie_par {
  font-size: 14px;
  color: rgba(25, 42, 69, 0.64);
  background: #F7F8FA;
  border-top: 1px solid #E0E2E5;
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
}
#container_contenu_global .bloc_msg > #bloc_publie_par .role,
#container_contenu_global #bloc_msg > #bloc_publie_par .role {
  padding: 5px 15px;
  text-align: center;
  background: #2D4962;
  color: #fff;
  font-size: 10px;
  display: inline-block;
  margin-left: 1rem;
}
#container_contenu_global .bloc_msg > #bloc_publie_par > #publie_par > span a:hover,
#container_contenu_global #bloc_msg > #bloc_publie_par > #publie_par > span a:hover {
  text-decoration: underline;
}
#container_contenu_global .bloc_msg > #bloc_publie_par > .column #date,
#container_contenu_global #bloc_msg > #bloc_publie_par > .column #date {
  padding-right: 20px;
}
#container_contenu_global #bloc_publie_par .right.floated #msg_date {
  margin-left: 0.5rem;
}
#container_contenu_global #bloc_publie_par .right.floated #msg_heure {
  margin-left: 0.5rem;
}
#container_contenu_global .bloc_msg > #bloc_texte p,
#container_contenu_global #bloc_msg > #bloc_texte p {
  text-align: justify;
  font-size: 16px;
  color: #132433;
  padding: 20px;
  width: 100%;
}
#container_contenu_global .bloc_msg > #bloc_texte p a,
#container_contenu_global #bloc_msg > #bloc_texte p a {
  word-wrap: break-word;
}
#container_contenu_global .bloc_msg > #bloc_texte p .quote_sujet,
#container_contenu_global #bloc_msg > #bloc_texte p .quote_sujet {
  font-style: italic;
  padding: .8rem;
  width: 100%;
  margin: auto auto 10px auto;
  text-align: left;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  vertical-align: baseline;
  background-color: #e8e8e8;
  background-image: none;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  border: 0 solid transparent;
  border-radius: .28571429rem;
  transition: background .1s ease;
  text-align: justify;
}
#container_contenu_global .bloc_msg > #bloc_texte p .is-fluo,
#container_contenu_global #bloc_msg > #bloc_texte p .is-fluo {
  background: #ffff00;
}
#container_contenu_global .bloc_msg > #bloc_texte p .derniere_modification,
#container_contenu_global #bloc_msg > #bloc_texte p .derniere_modification {
  font-style: italic;
  font-size: 14px;
}
#container_contenu_global .bloc_msg > #bloc_texte p .role,
#container_contenu_global #bloc_msg > #bloc_texte p .role {
  padding: 0px 15px;
  text-align: center;
  background: #2D4962;
  color: #fff;
  font-size: 10px;
  display: inline-block;
  margin-left: 1rem;
}
#container_contenu_global .bloc_msg > #bloc_rep,
#container_contenu_global #bloc_msg > #bloc_rep {
  text-align: right;
  width: 100%;
  margin-top: 10px;
}
#container_contenu_global .bloc_msg > #bloc_rep.left,
#container_contenu_global #bloc_msg > #bloc_rep.left {
  text-align: left;
}
#container_contenu_global .bloc_msg > #bloc_rep a.supp_com:hover,
#container_contenu_global #bloc_msg > #bloc_rep a.supp_com:hover {
  text-decoration: underline!important;
}
#container_contenu_global .bloc_msg > #bloc_rep a.supp_com:hover i,
#container_contenu_global #bloc_msg > #bloc_rep a.supp_com:hover i {
  text-decoration: none;
}
#container_contenu_global .bloc_msg > #bloc_rep a:hover,
#container_contenu_global #bloc_msg > #bloc_rep a:hover {
  text-decoration: underline;
  color: #2D4962;
}
#container_contenu_global .bloc_msg > #bloc_rep i,
#container_contenu_global #bloc_msg > #bloc_rep i {
  margin-left: 10px;
}
#container_contenu_global .bloc_msg > #bloc_rep .compact.menu {
  border-color: #3192C5;
}
#container_contenu_global .bloc_msg > #bloc_rep .compact.menu a {
  color: #3192C5;
}
#container_contenu_global .bloc_msg > #bloc_rep .compact.menu a:hover {
  background: rgba(33, 186, 69, 0.1);
}
#container_contenu_global .bloc_msg > #bloc_rep .compact.menu a > .label {
  display: table;
  left: unset;
  right: -1px;
}
#container_contenu_global .bloc_msg > #container_quest,
#container_contenu_global #bloc_msg > #container_quest {
  text-align: right;
  width: 100%;
  margin-top: 10px;
}
#container_contenu_global #append_msg {
  padding: 0!important;
}
#bloc_accept_offres > #check_accept label.text_accept {
  font-size: 12px;
  text-transform: initial;
}
#bloc_accept_cgu > #check_accept label.text_accept {
  font-size: 12px;
  text-transform: initial;
}
#bloc_accept_cgu > #check_accept label.text_accept a {
  color: #FF880F;
  font-weight: 600!important;
}
#bloc_accept_cgu > #check_accept label.text_accept a:hover {
  text-decoration: underline;
}
/**SUJETS**/
#bloc_pubs.sujet {
  display: none;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1200px) {
  #container_left #bloc_publie > .grid .left.floated .segment > span.auteur {
    display: block;
  }
}
@media all and (max-width: 1080px) {
  /***Réseaux sociaux****/
  #container_left #reseaux_sociaux > #container_rs_left {
    width: 100%!important;
  }
  /***Sujets****/
  #container_sujet_pubs {
    display: table;
  }
  .bloc_sujet_contenu {
    display: table-footer-group !important;
  }
  .container_sujet {
    display: table-footer-group !important;
  }
  .sujet_responsive {
    display: block;
  }
  #bloc_pubs {
    width: 100%!important;
  }
  #bloc_pubs.sujet {
    display: block;
  }
  #bloc_pubs.forum {
    padding: 0;
  }
  #bloc_pubs.sujet > .grid > .column {
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }
  /*#container_droite.sujet_accordion {
		display: none;
		&.posez_votre_question {
			display: none;
		}
	}*/
  .container_thematiques_forum_sujet_down .grid {
    display: grid;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: auto;
    margin-bottom: -3rem;
  }
}
@media all and (max-width: 767px) {
  .container_sujet h1 {
    font-size: 1.6em;
  }
  #bloc_pubs.forum {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .container_thematiques_forum_sujet_down .grid {
    margin-left: auto;
    margin-right: auto;
  }
}
@media all and (max-width: 658px) {
  /****BLOC GAUCHE****/
  /****Publié le****/
  #container_left #bloc_publie > .grid > .column {
    display: inherit;
    text-align: left;
    padding-bottom: 0;
    padding-top: 5px;
    width: 100%!important;
    float: left;
  }
  /****Publié par - MSG****/
  #container_contenu_global .bloc_msg > #bloc_publie_par .img_name_publie {
    width: 100%!important;
    text-align: center;
  }
  #container_contenu_global .bloc_msg > #bloc_publie_par .nb_msg_date {
    width: 100%!important;
    text-align: center;
    padding-top: 0;
  }
  #container_contenu_global #bloc_msg > #bloc_publie_par > #bloc_date {
    display: inline-grid;
  }
  #container_contenu_global #bloc_msg > #container_quest {
    text-align: center;
  }
  #container_contenu_global #bloc_msg > #container_quest > a > #btn_question {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  #container_contenu_global #container_repondre > #bloc_repondre > .container_btn_suppr {
    width: 100%!important;
  }
  #container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express > #bloc_btn_suppr {
    width: 100%;
  }
  #container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express > #bloc_btn_suppr button {
    width: 100%;
    padding: 15px 50px;
  }
  #container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express > #bloc_btn_pub {
    width: 100%;
  }
  #container_contenu_global #container_repondre > #bloc_repondre > #container_insc_express > #bloc_btn_pub button {
    width: 100%!important;
  }
  #container_contenu_global #container_repondre > #bloc_repondre > .container_btn_valid {
    width: 100%!important;
  }
}
@media all and (max-width: 400px) {
  #bloc_pubs.sujet {
    padding: 1rem;
  }
  #container_droite.sujet_accordion {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  #container_droite.sujet_accordion.sujet_forum {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #container_contenu_global .bloc_msg > #bloc_publie_par .column,
  #container_contenu_global #bloc_msg > #bloc_publie_par .column {
    width: 100%!important;
    padding-bottom: 0.5rem;
    text-align: left;
  }
  #container_contenu_global .bloc_msg > #bloc_publie_par .column#bloc_date,
  #container_contenu_global #bloc_msg > #bloc_publie_par .column#bloc_date {
    padding-top: 0;
    padding-bottom: 1rem;
  }
  #container_contenu_global .bloc_msg > #bloc_publie_par .role,
  #container_contenu_global #bloc_msg > #bloc_publie_par .role {
    display: block;
    margin-left: 0;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}
/*******************************[MESSAGERIE]***********************************/
/*bloc_right*/
#bloc_contenu_right {
  padding-top: 0;
  padding-left: 3rem;
}
#bloc_contenu_right > .grid {
  margin: 0;
  /****Affichage d'un message sélectionné****/
}
#bloc_contenu_right > .grid table {
  padding: 0;
}
#bloc_contenu_right > .grid table > thead th {
  background: none;
  border-bottom: none;
  color: #b0b0b0;
  font-weight: 100;
  padding-left: 0;
}
#bloc_contenu_right > .grid table > thead th.date {
  width: 15%;
}
#bloc_contenu_right > .grid table > thead th.delete {
  width: 20px;
}
#bloc_contenu_right > .grid table > thead th.expediteur {
  width: 150px;
}
#bloc_contenu_right > .grid table > tbody td {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  padding-left: 0;
  border-top: none;
  word-break: break-word;
}
#bloc_contenu_right > .grid table > tbody td > a {
  color: #50a2ce;
  word-break: initial;
}
#bloc_contenu_right > .grid table > tbody td > a.new_msg {
  font-weight: bold;
}
#bloc_contenu_right > .grid table > tbody td > .button {
  background: none;
  padding: 0;
  color: red;
  box-shadow: none;
  margin: 0 auto;
}
#bloc_contenu_right > .grid table > tbody td > .button i {
  margin: 0 auto;
}
#bloc_contenu_right > .grid .bloc_title,
#bloc_contenu_right > .grid .bloc_auteur {
  padding: 0;
  width: 100%;
}
#bloc_contenu_right > .grid .bloc_title h4 {
  padding: 0;
  margin-bottom: 14px;
}
#bloc_contenu_right > .grid .bloc_title p {
  color: rgba(25, 42, 69, 0.4);
  padding-bottom: 20px;
  font-weight: 500;
}
#bloc_contenu_right > .grid .bloc_auteur {
  font-size: 14px;
  color: rgba(25, 42, 69, 0.64);
  background: #F7F8FA;
  border-top: 1px solid #E0E2E5;
  padding: 20px;
}
#bloc_contenu_right > .grid .bloc_auteur span {
  display: block;
}
#bloc_contenu_right > .grid .bloc_auteur .auteur {
  float: left;
}
#bloc_contenu_right > .grid .bloc_auteur .date {
  float: right;
}
#bloc_contenu_right > .grid .bloc_txt_msg {
  text-align: justify;
  font-size: 14px;
  color: #132433;
  padding: 20px;
}
.selectize-control.destinataires.loading::before {
  opacity: 0.4;
}
.selectize-control.destinataires::before {
  transition: opacity 0.2s;
  content: ' ';
  z-index: 2;
  position: absolute;
  display: block;
  top: 50%;
  right: 34px;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 0;
  background: url(/ressources/images/messagerie/selectize-spinner.gif);
  background-size: 16px 16px;
  opacity: 0;
}
/****Nouveau message****/
#container_repondre > #bloc_repondre > .message {
  margin-left: 20px;
  margin-right: 20px;
}
#container_repondre > #bloc_repondre > .field {
  width: 100%;
}
#container_repondre > #bloc_repondre > #bloc_textarea textarea {
  height: 200px;
}
/*******************************[RESPONSIVE]********************************/
/**bloc right**/
@media all and (max-width: 1200px) {
  #bloc_contenu_right {
    width: 100%!important;
    padding: 0;
    margin-top: 10px;
    text-align: center;
  }
}
@media all and (max-width: 767px) {
  #bloc_contenu_right > .grid table > thead th {
    display: none!important;
  }
  #bloc_contenu_right > .grid table > tbody tr {
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
  }
  #bloc_contenu_right > .grid .bloc_auteur .auteur,
  #bloc_contenu_right > .grid .date {
    width: 100%;
    float: none;
  }
}
/**********************************[PREMIUM]***********************************/
/**Avantages Compte Premium**/
.container_menu_avantages {
  border: none!important;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.4em;
  padding-bottom: 30px;
  padding-top: 30px;
}
.container_menu_avantages a.item {
  background: none!important;
  border: none!important;
}
.container_menu_avantages a.item.active {
  color: #16374a!important;
}
.container_menu_avantages.ui.labeled.icon.menu .item > .icon:not(.dropdown) {
  font-size: 40px!important;
}
.tab_avantages blockquote {
  margin-top: -0.5rem;
}
.tab_avantages blockquote p {
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.6;
}
.tab_avantages blockquote footer {
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
}
/**Statut du compte**/
.container_status_compte {
  margin: 2rem 0!important;
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
  background: #3aa1d7!important;
  color: #FFF;
}
.container_status_compte .grid .row .column {
  box-shadow: none!important;
}
/**Prix Premium**/
.container_prices .segments {
  box-shadow: none!important;
  border: 1px solid #eaeaea;
  border-radius: 0;
}
.container_prices .segments.active {
  border: 1px solid #3aa1d7;
}
.container_prices .segments > .segment {
  border-bottom: 1px solid #f1f1f1;
}
.container_prices .segments > .segment.secondary {
  background: #FFF;
}
.container_prices .segments > .segment .ui.header {
  padding: 1rem;
}
.container_prices .segments > .segment .ui.header .icon {
  font-size: 1.5em!important;
}
.container_prices .segments > .segment .ui.header .content {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.container_prices .segments > .segment .ui.header .segment p {
  color: #777;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.container_prices .segments > .segment .statistic .value {
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
}
.container_prices .segments > .segment .statistic .label {
  color: #999;
  font-size: 12px;
}
/**********************************[RESPONSIVE]********************************/
@media all and (max-width: 1080px) {
  /**Avantages Compte Premium**/
  .container_menu_avantages {
    letter-spacing: .2em;
    display: table!important;
  }
  .container_menu_avantages a.item {
    width: 100%!important;
  }
  .container_menu_avantages a.item::before {
    background: 0 0!important;
  }
  .container_menu_avantages.ui.labeled.icon.menu .item > .icon:not(.dropdown) {
    font-size: 30px!important;
  }
  .tab_avantages .ui.grid > [class*="four wide"].column,
  .tab_avantages .ui.grid > [class*="twelve wide"].column {
    width: 100%!important;
  }
  .container_prices .segments > .segment .ui.button {
    width: 100%;
    padding: 1rem 0!important;
  }
}
@media all and (max-width: 767px) {
  /**Prices**/
  .container_prices .grid > .column {
    width: 100%!important;
    padding: 1rem 0 !important;
  }
}
/*********************************[PREMIUM +]**********************************/
/**Compteur du nbre de visiteurs**/
.container_nb_visiteurs,
.container_tarifs {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.container_nb_visiteurs h4 {
  display: grid!important;
  white-space: normal!important;
}
.container_nb_visiteurs h4 i.icon {
  margin: auto!important;
}
/********************************[PREVIEW]********************************/
#container_preview {
  margin-top: 3rem;
}
#container_preview #bloc_img_preview {
  text-align: center;
}
#container_preview #bloc_img_preview img {
  text-align: center;
  width: 100%;
  height: auto;
}
#container_preview #bloc_img_preview .blog_title_ss {
  position: absolute;
  top: 6%;
  left: 8%;
}
#container_preview #bloc_img_preview .blog_title_ss .title {
  margin: 0;
  text-align: left;
  font-weight: 600!important;
  font-size: 14px;
  color: #FFF;
}
#container_preview #bloc_img_preview .blog_title_ss .ss_title {
  margin: 0;
  text-align: left;
  font-size: 10px;
  font-weight: 600!important;
  color: rgba(0, 0, 28, 0.8);
}
#container_preview .description p {
  padding: 5px 20px;
}
/********************************[RESPONSIVE]********************************/
/********************************[CONTENU FORM]********************************/
/****bloc left****/
@media all and (max-width: 1200px) {
  #container_form {
    width: 100%!important;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 600px) {
  #container_contenu_creer_blog > .grid #container_form #url_acces_blog {
    display: grid;
  }
}
/****bloc right****/
@media all and (max-width: 1200px) {
  #container_preview {
    width: 100%!important;
    padding: 0;
  }
  #container_preview #bloc_img_preview .blog_title_ss {
    top: 50px;
  }
  #container_preview #bloc_img_preview .blog_title_ss .title {
    font-size: 40px;
  }
  #container_preview #bloc_img_preview .blog_title_ss .ss_title {
    font-size: 26px;
  }
}
@media all and (max-width: 991px) {
  #container_preview #bloc_img_preview .blog_title_ss .title,
  #container_preview #bloc_img_preview .blog_title_ss .ss_title {
    font-size: large;
  }
}
@media all and (max-width: 720px) {
  #container_preview #bloc_img_preview .blog_title_ss {
    left: 50px;
  }
  #container_preview #bloc_img_preview .blog_title_ss .title,
  #container_preview #bloc_img_preview .blog_title_ss .ss_title {
    font-size: small;
  }
}
@media all and (max-width: 650px) {
  #container_preview #bloc_img_preview .blog_title_ss {
    top: 45px;
    left: 48px;
  }
  #container_preview #bloc_img_preview .blog_title_ss .title,
  #container_preview #bloc_img_preview .blog_title_ss .ss_title {
    font-size: smaller;
  }
}
@media all and (max-width: 590px) {
  #container_preview #bloc_img_preview .blog_title_ss {
    top: 39px;
    left: 45px;
  }
}
@media all and (max-width: 490px) {
  #container_preview #bloc_img_preview .blog_title_ss {
    top: 34px;
    left: 40px;
  }
}
@media all and (max-width: 450px) {
  #container_preview #bloc_img_preview img {
    width: 100%;
  }
}
@media all and (max-width: 440px) {
  #container_preview #bloc_img_preview .blog_title_ss {
    display: none;
  }
  #container_preview #bloc_img_preview .blog_title_ss .title,
  #container_preview #bloc_img_preview .blog_title_ss .ss_title {
    display: none;
  }
}
@media all and (max-width: 400px) {
  #container_preview #bloc_img_preview img {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
/*******************************[UNE QUESTION SUR L'ARTICLE]********************************/
#container_left #container_question_article {
  display: flex;
}
#container_left #container_question_article #bloc_question_article {
  background: #F7F8FA!important;
}
#container_left #container_question_article #bloc_question_article > .grid > #bloc_title {
  background: #2D4962;
}
#container_left #container_question_article #bloc_question_article > .grid > #bloc_title > span {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #2D4962;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input {
  bottom: 0;
  position: absolute;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input > button {
  border: 1px solid #3192C5;
  color: #FFF;
  padding: 15px 50px;
  text-transform: uppercase;
  border-radius: 0px;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input > button:hover {
  background-color: #2D4962;
  color: #FFF;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input > button:hover + i {
  color: #FFF;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input > i {
  color: #2D4962;
  opacity: 1;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum > a.btn_aller_forum {
  display: block!important;
  margin-top: 1rem!important;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum > a.btn_aller_forum button {
  width: 100%;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question {
  margin-top: 20px;
  margin-bottom: 20px;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question.is-border {
  border-right: 1px solid #2D4962;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > #bloc_img_avocat > img {
  outline: 2px solid #2D4962;
  width: fit-content;
  margin: auto;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > #bloc_info > p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  color: #142E46;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > #bloc_info > #prix_avocat {
  font-size: 24px;
  font-weight: bold;
  color: #2D4962;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > #bloc_info #vote .star {
  margin: 0;
  color: rgba(0, 0, 0, 0.15);
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > #bloc_info #vote .star.selected {
  color: #2D4962;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > #bloc_posez_question {
  text-decoration: none!important;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > #bloc_posez_question button {
  margin-top: 0.7rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > a > .input > button {
  background-color: #3192C5;
  border: 1px solid #3192C5;
  color: #FFF;
  padding: 15px 50px;
  text-transform: uppercase;
  border-radius: 0px;
}
#container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > a > .input > button:hover {
  background-color: #FFF;
  border: 1px solid #3192C5;
  color: #3192C5;
}
/*******************************[RESPONSIVE]********************************/
@media all and (max-width: 1080px) {
  #container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum {
    width: 100%!important;
    border-right: none;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  #container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum a.btn_aller_forum {
    width: 100%;
    display: block!important;
    margin-bottom: 1rem!important;
  }
  #container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum a.btn_aller_forum button {
    margin: auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  #container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question {
    width: 100%!important;
    margin-top: 0px;
  }
  #container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question.is-border {
    border-right: none;
  }
  #container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > #bloc_img_avocat {
    width: 100%!important;
  }
  #container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > #bloc_info {
    width: 100%!important;
    text-align: center;
  }
  #container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_question > .grid > #bloc_posez_question {
    margin: auto;
    width: 100%;
  }
  #container_left #container_question_article #bloc_question_article > #bloc_forum_question > #bloc_forum > a > .input {
    bottom: unset;
    position: relative;
  }
}
/********************************[RESEAUX SOCIAUX]*****************************/
/**Icones**/
.fa-facebook-f {
  background: url('/ressources/images/brands/facebook-f.svg');
}
.fa-twitter {
  background: url('/ressources/images/brands/twitter.svg');
}
.fa-linkedin {
  background: url('/ressources/images/brands/linkedin.svg');
}
.fa-android {
  background: url('/ressources/images/brands/android.svg');
}
.fa-apple {
  background: url('/ressources/images/brands/apple.svg');
}
.fa-facebook-f,
.fa-twitter,
.fa-linkedin,
.fa-apple,
.fa-android {
  background-size: cover;
  background-repeat: round;
}
.fa-facebook-f::before,
.fa-twitter::before,
.fa-linkedin::before,
.fa-apple::before,
.fa-android::before {
  content: "";
}
/**Accueil**/
#container_search_rs h1 {
  color: #192A45;
}
#container_search_rs.rs_blog {
  padding: 2rem;
}
#container_search_rs.rs_blog #bloc_title {
  text-align: left;
  padding-left: 0;
  padding-top: 0;
  margin-bottom: 20px;
}
#container_search_rs.rs_blog span {
  color: #2D4962;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
#container_search_rs.rs_blog #bloc_rs {
  padding: 0;
}
#container_search_rs.rs_blog #bloc_rs #rs {
  background: #66ABE7;
  padding: 10px;
  display: table;
  margin: auto;
  min-width: 52px;
}
#container_search_rs.rs_blog #bloc_rs #rs i {
  vertical-align: middle;
  margin: auto;
  font-size: 2em;
  display: table-cell;
  color: #FFF;
}
#container_search_rs.rs_blog #bloc_rs #rs i.fab {
  height: 29px;
  width: 24.5px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(136deg) brightness(101%) contrast(102%);
}
#container_search_rs.rs_blog #bloc_rs #rs:hover i {
  color: #66ABE7 !important;
}
#container_search_rs.rs_blog #bloc_rs > a {
  text-decoration: none!important;
}
#container_search_rs.rs_blog #bloc_rs > a > #rs:hover {
  outline: 1px solid #66ABE7 !important;
  background: #FFF!important;
}
#container_search_rs.rs_blog #bloc_rs p {
  margin-top: 5px;
  font-size: 10px;
  font-weight: 600!important;
  color: #66ABE7;
  text-transform: uppercase;
}
#container_search_rs #container_rs {
  margin-top: auto;
  margin-bottom: auto;
  width: 33.3%;
}
#container_search_rs #container_rs > .grid {
  padding-left: 0.5rem;
}
#container_search_rs #container_rs > .grid > .column {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
#container_search_rs #container_rs > .grid > .column > a > .bloc_rs {
  background-color: #FA980D;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
}
#container_search_rs #container_rs > .grid > .column > a > .bloc_rs:hover {
  outline: 1px solid #FA980D;
  background-color: #FFF;
}
#container_search_rs #container_rs > .grid > .column > a > .bloc_rs:hover > i {
  color: #FA980D;
}
#container_search_rs #container_rs > .grid > .column > a > .bloc_rs > i {
  margin: auto;
  font-size: 2em;
  line-height: 50px;
  color: #FFF;
}
#container_search_rs #container_rs > .grid > .column > a > .bloc_rs > i.fab {
  height: 29px;
  width: 24.5px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(136deg) brightness(101%) contrast(102%);
}
#container_search_rs #container_rs > .grid > .column > a:hover {
  text-decoration: none!important;
}
#container_search_rs #container_rs > .grid > .column > a:hover > .bloc_rs > i {
  filter: invert(54%) sepia(99%) saturate(441%) hue-rotate(354deg) brightness(99%) contrast(97%);
}
#container_left #reseaux_sociaux > #container_rs_left {
  padding: 0;
  height: 49px;
}
#container_left #reseaux_sociaux > #container_rs_left .at-share-btn {
  margin-bottom: 0;
  background-color: #2D4962 !important;
}
#container_left #reseaux_sociaux > #container_rs_left > .grid > .column {
  background: #2D4962;
  margin-right: 2px;
  height: 50px;
}
#container_left #reseaux_sociaux > #container_rs_left > .grid > .column:hover {
  background-color: #FFF;
  color: #3192C5;
  outline: 1px solid #3192C5;
}
#container_left #reseaux_sociaux > #container_rs_left > .grid > .column:hover > .bloc_rs i {
  color: #3192C5;
}
#container_left #reseaux_sociaux > #container_rs_left > .grid > .column > .bloc_rs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
#container_left #reseaux_sociaux > #container_rs_left > .grid > .column > .bloc_rs i {
  color: #FFF;
  font-size: 1.8em;
  margin: auto;
  transform: translateY(50%);
}
#container_left #reseaux_sociaux > #container_rs_right {
  background: #EAF4F9;
}
#container_left #reseaux_sociaux > #container_rs_right.forum {
  width: 36%!important;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1200px) {
  #container_search_rs.rs_blog #bloc_rs {
    font-size: 10px;
  }
  #container_search_rs #container_rs > .grid {
    padding-left: 0;
  }
  #container_search_rs #container_rs > .grid > .column {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #container_search_rs #container_rs > .grid > .column > a > .bloc_rs {
    width: auto;
  }
}
@media all and (max-width: 1080px) {
  #container_rs {
    width: 100%!important;
    margin-top: 10px!important;
  }
  #container_rs.rs_blog #bloc_rs {
    font-size: initial;
  }
  #container_search_rs .bloc_h1 {
    width: 100%!important;
  }
  #container_search_rs.rs_blog #bloc_rs #rs {
    margin: auto;
  }
  #container_search_rs.rs_blog #bloc_title {
    text-align: center;
  }
  #container_search_rs > .grid > .row > #container_rs > .grid > .column > a > .bloc_rs {
    background-color: inherit!important;
  }
  #container_search_rs > .grid > .row > #container_rs > .grid > .column > a > .bloc_rs > i {
    color: #FA980D!important;
  }
  /**Reseaux sociaux en dessous du titre**/
  #container_left #reseaux_sociaux > #container_rs_left {
    width: 100%!important;
    height: auto;
    padding-right: 0;
    text-align: center;
  }
  #container_left #reseaux_sociaux > #container_rs_left.forum {
    width: 100%!important;
    margin: auto;
  }
  #container_left #reseaux_sociaux > #container_rs_right {
    width: 100%!important;
    margin-left: 0px;
    margin-top: 10px;
    margin-right: 1rem;
  }
  #container_left #reseaux_sociaux > #container_rs_right.forum {
    margin-right: 0;
    width: 100%!important;
  }
}
@media all and (max-width: 700px) {
  #container_search_rs h1 i {
    font-size: small;
  }
}
@media all and (max-width: 400px) {
  #container_search_rs {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  #container_search_rs h1 i {
    display: none;
  }
  #container_search_rs h1 div {
    padding-left: 0;
  }
  #container_search_rs.rs_blog {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  #container_search_rs.rs_blog #bloc_rs {
    width: 100%!important;
    margin-bottom: 1rem;
  }
  #container_search_rs.rs_blog #bloc_title {
    padding-right: 0;
    margin: auto;
  }
  #container_search_rs #container_rs {
    padding-top: 0px!important;
  }
}
/**********************************[SEARCH]************************************/
#container_search_rs {
  padding-left: 30px;
  padding-right: 30px;
}
#container_search {
  width: 66.7%;
}
#container_search.pagesearch {
  width: 100%;
}
#container_search #cover {
  border: 1px solid rgba(34, 36, 38, 0.15);
}
#container_search #cover .tb {
  display: table;
  width: 100%;
}
#container_search #cover .td {
  display: table-cell;
  vertical-align: middle;
}
#container_search #cover input,
#container_search #cover button {
  color: #999;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
}
#container_search #cover input {
  padding: 15px 20px 15px 20px;
  width: 100%;
}
#container_search #cover input::placeholder {
  color: #999;
}
#container_search #cover input:focus {
  outline: none !important;
}
#container_search #cover #s-cover {
  width: 1px;
  padding-left: 35px;
  transition: all 0.2s ease-in-out;
}
#container_search #cover #s-cover:hover {
  transform: scale(1.1);
}
#container_search #cover button {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
#container_search #cover button span {
  position: absolute;
  top: 11px;
  left: 7px;
  display: block;
  width: 45px;
  height: 15px;
  background-color: transparent;
  border-radius: 10px;
  transform: rotateZ(52deg);
  transition: 0.5s ease all;
}
#container_search #cover button span::before,
#container_search #cover button span::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 2px;
  background-color: rgba(34, 36, 38, 0.15);
  border-radius: 10px;
  transform: rotateZ(0);
  transition: 0.5s ease all;
}
#container_search #cover #s-circle {
  position: relative;
  top: -6px;
  left: 10px;
  width: 22px;
  height: 22px;
  margin-top: 0;
  border-width: 15px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  background-color: transparent;
  border-radius: 50%;
  transition: 0.5s ease all;
}
/**Recherche globale**/
.icon_recherche_global {
  position: absolute!important;
  top: 50%!important;
  transform: translateY(-50%) !important;
  font-size: 1.2em!important;
  padding-left: 20px!important;
  color: #999!important;
}
.input_recherche_global {
  border-radius: 0!important;
  width: 100%!important;
  padding: 15px 20px 15px 50px!important;
  color: #999!important;
  font-size: 15px!important;
}
.container_blogs .contenu_blog {
  padding: 10px;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 1080px) {
  #container_search_rs #container_rs {
    margin-bottom: 20px;
    padding-top: 20px;
  }
  #container_search {
    display: none;
  }
}
/******[SELECTION D'UNE COULEUR : ADMINISTRATION / APPARENCE]******************/
#bloc_couleur_principale {
  display: block;
  margin-top: 2em;
  margin-bottom: 2em;
}
#bloc_couleur_principale label {
  position: relative;
  cursor: pointer;
  margin-bottom: 1em;
}
#bloc_couleur_principale label span {
  width: 85px;
  display: block;
  height: 74px;
}
#bloc_couleur_principale label span#bleu {
  background: #3AA1D7;
}
#bloc_couleur_principale label span#rouge {
  background: #B90033;
}
#bloc_couleur_principale label span#beige {
  background: #DEBD8E;
}
#bloc_couleur_principale label span#vert {
  background: #506433;
}
#bloc_couleur_principale label span#violet {
  background: #660066;
}
#bloc_couleur_principale label span#rose {
  background: #a56483;
}
#bloc_couleur_principale label span#gris {
  background: #626262;
}
#bloc_couleur_principale label span#noir {
  background: #1d1d1d;
}
#bloc_couleur_principale label span#orange {
  background: #a55a16;
}
#bloc_couleur_principale label span#bleu_gris {
  background: #3e606f;
}
#bloc_couleur_principale label span#jaune {
  background: #bd9f54;
}
#bloc_couleur_principale label span#marron {
  background: #9d6e52;
}
#bloc_couleur_principale label span#fushia {
  background: #771a2d;
}
#bloc_couleur_principale label span#mauve {
  background: #a38d98;
}
#bloc_couleur_principale label span#autre_beige {
  background: #D9C5A2;
}
#bloc_couleur_principale label span#autre_vert {
  background: #99AD6B;
}
#bloc_couleur_principale label span#autre_violet {
  background: #6C5B7D;
}
#bloc_couleur_principale label span#autre_orange {
  background: #ecab75;
}
#bloc_couleur_principale input[type="radio"] {
  display: none;
}
#bloc_couleur_principale input[type="radio"]:checked + label span {
  border: 3px solid #000;
  display: inline-block;
}
#bloc_couleur_principale input[type="radio"]:checked + label span::before {
  content: "\f00c";
  position: absolute;
  color: #FFF;
  font-size: 1.2em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-style: normal;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#bloc_couleur_principale .field {
  display: inline-block;
  padding-right: 1.5em;
  padding-bottom: 1em;
}
#bloc_couleur_principale [type="radio"] + label span {
  display: inline-block;
  position: relative;
  padding: 1em;
}
/********************************[RESPONSIVE]**********************************/
@media all and (max-width: 425px) {
  #bloc_couleur_principale {
    text-align: center;
  }
}
/**********************************[SITEMAP]***********************************/
#container_left .plan_level_1 {
  font-size: 1.2em;
}
#container_left .plan_level_2 {
  font-size: 1.0em;
}
#container_left .plan_level_3 {
  font-size: 0.8em;
}
/**Themes**/
/*@import 'modules/themes/beige';
@import 'modules/themes/bleu_gris';
@import 'modules/themes/bleu';
@import 'modules/themes/fushia';
@import 'modules/themes/gris';
@import 'modules/themes/jaune';
@import 'modules/themes/marron';
@import 'modules/themes/mauve';
@import 'modules/themes/noir';
@import 'modules/themes/orange';
@import 'modules/themes/rose';
@import 'modules/themes/rouge';
@import 'modules/themes/vert';
@import 'modules/themes/violet';*/
/**Autres Themes**/
/*
@import 'modules/themes/autre_beige';
@import 'modules/themes/autre_vert';
@import 'modules/themes/autre_violet';
@import 'modules/themes/autre_orange';*/
/**Premium**/
/*********************************[BLOC PREMUIM]*******************************/
.bloc_premium.is-actif {
  position: relative;
  display: block;
  padding: 30px 0;
}
.bloc_premium.is-actif:hover .bloc_premium_aplat {
  opacity: 0.6;
}
.bloc_premium.is-actif .bloc_premium_aplat {
  display: block;
}
.bloc_premium.is-actif .bloc_premium_texte {
  display: block;
}
.bloc_premium .bloc_premium_aplat {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(45deg, #cdcdcd, #cdcdcd 30px, #ececec 30px, #ececec 60px);
  opacity: 0.9;
  color: #000;
  z-index: 10;
  border: 2px dashed #888;
  transition-duration: 0.2s;
}
.bloc_premium .bloc_premium_texte {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  font-weight: bold;
  color: #000;
  text-decoration: none;
  font-size: 14px;
}
.bloc_premium .bloc_premium_texte span {
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.bloc_premium .bloc_premium_texte em {
  font-size: 12px;
}
